import {
  MDBRow,
  MDBContainer,
  MDBCol,
  MDBBox,
  MDBBtn,
  MDBInput,
} from "mdbreact";
import React, { useMemo, useState } from "react";
import Icon from "app/assets/icon/icon";
import { useMediaQuery } from "react-responsive";
import Select from "app/layout/Select";
import Listing from "./Listing/Listing";
import themeConfiguration from "config/themeConfiguration";
import FilterOptions from "../../product/fabricSidebar/filters/filterTypes/multiSelectFilterItem/filterOptions/filterOptions";
import Table from "./Table/Table";

const Grade = ({
  categories,
  setCategoryFilter,
  category,
  pattern,
  setPattern,
  setPatternLike,
  vendor,
  setVendor,
  currentPage,
  setCurrentPage,
  pageSizeOptions,
  pageSize,
  setPageSize,
  vendors,
  searchVal,
  setSearchVal,
  searchGrades,
  logoData,
  patterns,
  foundVendor,
  allGrades,
  grades,
  setShowTable,
  showTable,
  setGrades,
  setPatterns,
  setSortingValues,
  setActiveFilters,
  selectedGrades,
  setSelectedGrades,
}) => {
  const isTablet = useMediaQuery({ maxWidth: 992 });
  let showDropdown = useMemo(() => {
    return patterns.find((patt) => patt.value === pattern);
  }, [pattern]);

  const [resetPatterns, setResetPatterns] = useState(false);

  return (
    <MDBContainer fluid className="grade-container">
      <MDBRow
        className="bg-gray-lighten-4"
        style={{ whiteSpace: "nowrap", overflowX: "auto" }}
      >
        <MDBCol>
          <MDBContainer className="overflow-auto">
            <MDBRow>
              <MDBCol sm={isTablet ? 12 : 6}>
                {categories.sort().map((cat, index) => {
                  return (
                    <MDBBox
                      key={cat}
                      // @ts-ignore
                      onClick={() => {
                        if (cat === "Grade Lookup") {
                          setGrades([]);
                          setSelectedGrades([]);
                          setShowTable(false);
                        }
                        setCategoryFilter(cat);
                        setSortingValues({});
                      }}
                      tag="div"
                      className={`tab horisontal-tab pt-3 h-100 mr-5 ${
                        category === cat ? "active" : ""
                      }`.trim()}
                    >
                      {cat}
                    </MDBBox>
                  );
                })}
              </MDBCol>
              {!isTablet && (
                <MDBCol
                  sm={6}
                  className={`d-flex align-items-center justify-content-end`}
                >
                  <MDBBtn
                    className="m-0 w-100 vendor-list-btn"
                    color="secondary"
                    onClick={(/** @type {React.MouseEvent} */ e) => {
                      window.location.href = `${themeConfiguration.magento_url}pub/media/textile_alliance/pdf/Fairfield_FOM_Grade_Database.xlsx`;
                    }}
                  >
                    <Icon icon={`docIcon`} size="2" className="mr-3" />
                    Download Full Vendor List
                  </MDBBtn>
                </MDBCol>
              )}
            </MDBRow>
          </MDBContainer>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBContainer className="filters-part">
            <div className="filter-container">
              <MDBRow>
                {isTablet && (
                  <MDBCol
                    size="12"
                    className={`d-flex align-items-center justify-content-end`}
                  >
                    <MDBBtn
                      className="m-0 w-100 vendor-list-btn"
                      color="secondary"
                      onClick={(/** @type {React.MouseEvent} */ e) => {
                        window.location.href = `${themeConfiguration.magento_url}pub/media/textile_alliance/pdf/Fairfield_FOM_Grade_Database.xlsx`;
                      }}
                    >
                      <Icon icon={`docIcon`} size="2" className="mr-3" />
                      Download Full Vendor List
                    </MDBBtn>
                  </MDBCol>
                )}
                {!category.includes("Search by Grade") ? (
                  <>
                    <MDBCol md="4">
                      <Select
                        placeholder="Select Program Partner"
                        className={`lg ${isTablet && "mb-3 mt-3"}`}
                        options={vendors}
                        onChange={(e) => {
                          setResetPatterns(true);
                          setVendor(e.value);
                          setPattern("");
                          setTimeout(() => {
                            setResetPatterns(false);
                          }, 0);
                        }}
                      />
                    </MDBCol>
                    <MDBCol md="4">
                      {!vendor || resetPatterns ? (
                        <MDBInput
                          hint="Enter Pattern"
                          containerClass={`m-0 w-100 ${isTablet && "mb-3"}`}
                          value={pattern}
                          disabled={true}
                        />
                      ) : (
                        <Select
                          placeholder="Enter Pattern"
                          className={`lg ${isTablet && "mb-3 mt-3"}`}
                          options={patterns}
                          onChange={(e) => {
                            setPattern(e.value);
                          }}
                          onInputChange={(e) => {
                            setPatternLike(e);
                          }}
                          disabled={!vendor}
                        />
                      )}
                      {/* <MDBInput
                        hint="Enter Pattern"
                        containerClass={`m-0 w-100 ${isTablet && "mb-3"}`}
                        value={pattern}
                        onFocus={(e) => {
                          if (e.target.value.length > 0) setIsOpen(true);
                        }}
                        onChange={(e) => {
                          if (e.target.value.length > 0) {
                            setIsOpen(true);
                          } else {
                            setIsOpen(false);
                          }
                          setPattern(e.target.value);
                        }}
                        disabled={!vendor}
                        //   className={clsx({
                        //     error: form.touched.email && form.errors.email,
                        //   })}
                      /> */}
                      {/* {patterns.length > 1 &&
                        pattern.length > 0 &&
                        !showDropdown &&
                        isOpen && (
                          <div className="filter-pattern">
                            {patterns
                              .filter((patternData) =>
                                patternData.includes(pattern)
                              )
                              .map((patt, i) => (
                                <p
                                  className="data"
                                  onClick={() => setPattern(patt)}
                                  key={i}
                                >
                                  {patt}
                                </p>
                              ))}
                            {isEmptyPattern.length === 0 && (
                              <p className="no-data">No results found</p>
                            )}
                          </div>
                        )} */}
                    </MDBCol>
                  </>
                ) : (
                  <MDBCol md="8">
                    <MDBInput
                      className={`search-input`}
                      hint="Search Grade"
                      value={searchVal}
                      containerClass="search-wrapper"
                      onChange={(e) => {
                        setSearchVal(e.target.value);
                        searchGrades();
                      }}
                    >
                      <Icon icon="search" />
                    </MDBInput>
                  </MDBCol>
                )}
                <MDBCol md="4">
                  {!category.includes("Search by Grade") ? (
                    <MDBBtn
                      className={`m-0 ${isTablet && "w-100"}`}
                      onClick={searchGrades}
                      disabled={!vendor || !showDropdown}
                    >
                      Search
                    </MDBBtn>
                  ) : (
                    <MDBBtn
                      className={`m-0 ${isTablet && "w-100"}`}
                      onClick={() => {
                        setShowTable(true);
                        searchGrades();
                      }}
                    >
                      Search
                    </MDBBtn>
                  )}
                </MDBCol>
              </MDBRow>
            </div>
          </MDBContainer>
        </MDBCol>
      </MDBRow>
      <MDBContainer>
        {category.includes("Search by Grade") && (
          <FilterOptions
            options={allGrades?.filter((grades) =>
              grades.value.toLowerCase().includes(searchVal.toLowerCase())
            )}
            filterCode={`ffifabrics_grade`}
            filterType={`mutli`}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            activeFilters={{}}
            addFilter={() => {}}
            setActiveFilters={setActiveFilters}
            selectedGrades={selectedGrades}
          />
        )}
      </MDBContainer>
      {Object.keys(foundVendor).length > 1 &&
        !category.includes("Search by Grade") && (
          <MDBContainer className="selected-vendor d-flex">
            <img src={foundVendor?.file} alt={vendor} className="img-fluid" />
            <div className="d-flex flex-column">
              <p>Pattern Name: {foundVendor?.fabric_pattern}</p>
              <p>Grade: {foundVendor?.fairfield_grade}</p>
              <p>Railroad: {foundVendor?.railroad}</p>
            </div>
          </MDBContainer>
        )}

      {logoData && (!showTable || category.includes("Grade Lookup")) && (
        <MDBContainer>
          <MDBRow>
            {logoData
              .sort((a, b) => {
                return a.vendor.localeCompare(b.vendor);
              })
              .map(({ vendor, logo_file, website, pdf }, i) => (
                <Listing
                  key={i}
                  vendor={vendor}
                  logo_file={logo_file}
                  website={website}
                  pdf={pdf}
                />
              ))}
          </MDBRow>
        </MDBContainer>
      )}
      {showTable && category.includes("Search by Grade") && (
        <MDBContainer>
          <Table
            grades={grades}
            pageSize={pageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            pageSizeOptions={pageSizeOptions}
            setSortingValues={setSortingValues}
            selectedGrades={selectedGrades}
          />
        </MDBContainer>
      )}
    </MDBContainer>
  );
};
export default Grade;
