import React, { FC, useMemo } from "react";

import { Aggregation, Maybe } from "app/generated/graphql";
import {
  AttributeCode,
  IFilter,
  getAggregation,
} from "app/pages/catalog/category/utils/utils";
import MultiRangeSlider from "multi-range-slider-react";

export interface SelectDimensionProps {
  filter: IFilter;
  aggregations: Array<Maybe<Aggregation>>;
  setDimensionFilter: (value: any) => void;
  dimension: AttributeCode;
  isMobile: boolean;
}

const SelectDimension: FC<SelectDimensionProps> = (props) => {
  const { aggregations, setDimensionFilter, dimension } = props;

  const dimensionAggregation = React.useMemo(
    () => getAggregation(dimension, aggregations),
    [aggregations]
  );
  const minQty: number = useMemo(() => {
    if (dimensionAggregation)
      return parseInt(
        dimensionAggregation?.options?.[0]?.value.split("_")?.[0] || ""
      );
    else return 0;
  }, [dimensionAggregation]);
  const maxQty: number = useMemo(() => {
    const options = dimensionAggregation?.options;
    if (options) {
      let mP = 0;
      return Math.max(
        mP,
        parseInt(options?.[options.length - 1]?.value.split("_")?.[1] || "")
      );
    } else return 0;
  }, [dimensionAggregation]);

  const dimensionPlaceholder = useMemo(() => {
    let dm = dimension?.split("_")?.[1];
    return `${dm?.[0]?.toUpperCase()}${dm?.slice(1)}`;
  }, [dimension]);

  const [dynamicValues, setDynamicValues] = React.useState({
    from: minQty,
    to: maxQty,
  });

  return (
    <div className="dimensions-wrapper">
      <p className="fs-14 fwregular ">{dimensionPlaceholder}</p>
      <div className="static-values">
        <p>{minQty}</p>
        <p>{maxQty}</p>
      </div>
      <MultiRangeSlider
        min={minQty}
        max={maxQty}
        baseClassName="multi-range-slider"
        step={1}
        ruler={false}
        label={false}
        preventWheel={true}
        minValue={minQty}
        maxValue={maxQty}
        onInput={(e) =>
          setDynamicValues({
            from: e.minValue,
            to: e.maxValue,
          })
        }
        onChange={(e) =>
          setDimensionFilter({
            from: e.minValue,
            to: e.maxValue,
          })
        }
      />
      <div className="dynamic-values">
        <p>{dynamicValues?.from}</p>
        <p>{dynamicValues?.to}</p>
      </div>
    </div>
  );
};

export default SelectDimension;
