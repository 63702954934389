import newBadge from "app/assets/icon/newBadge.svg";
import { MDBCol, MDBRow, MDBTypography, MDBBox } from "mdbreact";
import React from "react";
import { Link } from "react-router-dom";

const FabricPatternWidget = ({
  image = { url: "http://via.placeholder.com/800", label: "placeholder" },
  sku = "FAbric Pattern Widget",
  name = "FAbric Pattern Widget Name",
  colSize = 3,
  mdSize = colSize,
  lgSize = mdSize,
  url_key = "#",
  url = url_key,
  className,
  onClick,
  isFavorite,
  is_new,
  onToggleFavorite,
  badges,
  price,
}) => {
  // @TODO: REMOVE THIS WHEN BACKEND IS FIXED
  if (image.url.includes("placeholder")) {
    let randomWidth = 800;
    let randomHeight = 800;
    image.url =
      "http://via.placeholder.com/" + randomWidth + "x" + randomHeight;
  }

  return (
    <MDBCol
      size={colSize}
      md={mdSize}
      lg={lgSize}
      className={`fabric-pattern-widget ${className}`.trim()}
    >
      <Link
        to={`/${url}`}
        onClick={onClick}
        className="d-flex flex-column h-100"
      >
        <MDBRow className="flex-fill flex-center position-relative mb-5">
          <div className="position-relative w-100">
            <img
              src={image.url}
              className="img-fluid"
              style={{
                maxHeight: "25.55rem",
                width: "100%",
                objectFit: "cover",
              }}
              alt={image.label}
            />
            {is_new ? (
              <img
                src={newBadge}
                alt="New"
                className="position-absolute m-3"
                style={{ top: 0, left: 0 }}
              />
            ) : null}
          </div>
          <div
            className="badges position-absolute d-flex justify-content-center mb-3 mx-auto"
            style={{ bottom: "1%", left: 0, right: 0, zIndex: 2 }}
          >
            {badges}
          </div>
        </MDBRow>
        <MDBRow>
          <MDBCol className="hover-bg-gray-lighten-3 fabric-pattern-widget-info border-bottom-3">
            <MDBBox className="d-flex justify-content-end mr-1">
              <MDBTypography tag="h4" className="w-100 text-right">
                {sku}
              </MDBTypography>
            </MDBBox>
            <MDBBox className="d-flex justify-content-start ml-1">
              <MDBTypography
                tag="h5"
                className="w-100 text-left fwmedium fs-16 text-lowercase"
              >
                {name}
              </MDBTypography>
            </MDBBox>
          </MDBCol>
        </MDBRow>
      </Link>
    </MDBCol>
  );
};

export default React.memo(FabricPatternWidget);
