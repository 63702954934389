import { useQuery } from "@apollo/react-hooks";
import OfflinePage from "app/layout/OfflinePage";
import ErrorPage from "app/pages/error";
import React, { useState } from "react";
// import ReactLoader from "../loader";
import Component from "./dynamicGraphQlWrapper";

const DynamicGraphQlWrapperQueryFetchContainer = (props) => {
  let LoadingComponent = props.loadingComponent;
  const [productTypeFinish, setProductTypeFinish] = useState(false);
  const { loading, error, data, refetch } = useQuery(props.query, {
    variables: props.variables,
  });

  if (loading)
    return (
      <div>
        {typeof LoadingComponent !== "undefined" ? <LoadingComponent /> : <></>}
      </div>
    );

  if (error?.message === "Network error: Failed to fetch") {
    return <OfflinePage refetch={refetch} />;
  }
  if (error) return <div>Error: {error.message}</div>;

  const children = React.Children.map(props.children, (child) => {
    return React.cloneElement(child, { ...props, data: data });
  });

  if (!props.isCached && typeof data !== "undefined") {
    if ("urlResolver" in data) {
      if (data.urlResolver === null) {
        return <ErrorPage {...props} />;
      } else if (
        data.urlResolver.id &&
        data.urlResolver.type === "CMS_PAGE" &&
        !props.isCached
      ) {
        props.addQueryData(
          { ...data },
          props.variables.url,
          data.urlResolver.id
        );
      } else if (
        data.urlResolver.id &&
        data.urlResolver.type === "CATEGORY" &&
        typeof props.queries !== "undefined" &&
        typeof props.queries[props.variables.url] !== "undefined"
      ) {
        props.addQueryData(
          { url_key: data.urlResolver.relative_url },
          props.variables.url,
          data.urlResolver.id
        );
      } else if (
        data.urlResolver.id &&
        data.urlResolver.type === "PRODUCT" &&
        !props.isCached &&
        !productTypeFinish
      ) {
        props.addQueryData(
          { ...data },
          props.variables.url,
          data.urlResolver.id
        );
        setProductTypeFinish(true);
      }
    }
  }

  return <Component {...props}>{children}</Component>;
};

export default DynamicGraphQlWrapperQueryFetchContainer;
