import React, { useCallback } from "react";
import { MDBPagination, MDBPageItem, MDBPageNav, MDBIcon } from "mdbreact";
import { useMediaQuery } from "react-responsive";
import { withRouter } from "react-router-dom";

const Pagination = ({ currentPage, totalPages, setCurrentPage, history }) => {
  let nextPage = +currentPage < totalPages ? +currentPage + 1 : false;
  let prevPage = +currentPage > 1 ? +currentPage - 1 : false;
  let lastSep = totalPages - +currentPage > 2;
  let firstSep = +currentPage > 3;
  let showFirst = +currentPage > 2;
  let showLast = totalPages - +currentPage > 1;
  let isMobile = useMediaQuery({ maxWidth: 992 });

  const scrollToTop = useCallback(() => {
    setTimeout(() => {
      const isCustomerPagination = window.location.pathname.includes(
        "customer/account/request-quote-history"
      )
        ? true
        : false;
      if (isCustomerPagination) window.scrollTo({ top: 0, behavior: "smooth" });
      else
        document.querySelector(".plp-counter-pagination-wrapper") &&
          document
            .querySelector(".plp-counter-pagination-wrapper")
            .scrollIntoView({
              behavior: "smooth",
            });
    }, 100);
  }, [currentPage]);

  return (
    <MDBPagination>
      <MDBPageItem
        disabled={!prevPage}
        onClick={() => {
          setCurrentPage(prevPage);
          scrollToTop();
        }}
      >
        <MDBPageNav aria-label="Previous">
          <MDBIcon icon="caret-left" />
        </MDBPageNav>
      </MDBPageItem>

      {showFirst && (
        <MDBPageItem
          onClick={() => {
            setCurrentPage(1);
            scrollToTop();
          }}
        >
          <MDBPageNav aria-label="First">1</MDBPageNav>
        </MDBPageItem>
      )}
      {!isMobile && firstSep && (
        <MDBPageItem>
          <MDBPageNav>. . .</MDBPageNav>
        </MDBPageItem>
      )}

      {prevPage && (
        <MDBPageItem
          onClick={() => {
            setCurrentPage(prevPage);
            scrollToTop();
          }}
        >
          <MDBPageNav aria-label="current">{prevPage}</MDBPageNav>
        </MDBPageItem>
      )}

      <MDBPageItem active>
        <MDBPageNav aria-label="current">{currentPage}</MDBPageNav>
      </MDBPageItem>

      {nextPage && (
        <MDBPageItem
          onClick={() => {
            setCurrentPage(nextPage);
            scrollToTop();
          }}
        >
          <MDBPageNav aria-label="current">{nextPage}</MDBPageNav>
        </MDBPageItem>
      )}

      {lastSep && (
        <MDBPageItem>
          <MDBPageNav>. . .</MDBPageNav>
        </MDBPageItem>
      )}

      {showLast && (
        <MDBPageItem
          onClick={() => {
            setCurrentPage(totalPages);
            scrollToTop();
          }}
        >
          <MDBPageNav aria-label="First">{totalPages}</MDBPageNav>
        </MDBPageItem>
      )}

      <MDBPageItem
        disabled={!nextPage}
        onClick={() => {
          setCurrentPage(nextPage);
          scrollToTop();
        }}
      >
        <MDBPageNav aria-label="next">
          <MDBIcon icon="caret-right" />
        </MDBPageNav>
      </MDBPageItem>
    </MDBPagination>
  );
};

export default withRouter(Pagination);
