import { useBoolean } from "ahooks";
import Icon from "app/assets/icon/icon";
import { Aggregation, Maybe, SortEnum } from "app/generated/graphql";
import Label from "app/layout/Label";
import Modal from "app/layout/Modal";
import ModalHeader from "app/layout/ModalHeader";
import PriceSlider from "app/layout/PriceSlider/PriceSlider";
import Select from "app/layout/Select";
import Switch from "app/layout/Switch";
import { createEnterHandler } from "app/utils/utils";
import { pathnameIncludes } from "app/utils/utils";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React, { useCallback, useMemo } from "react";
import {
  DragTarget,
  eventToPercentage,
  maxPercentage,
  minimumPercentageRange,
  minPercentage,
  percentageToPrice,
} from "../utils/price";
import {
  appendCountToMaybeOptionsLabel,
  Filters,
  getAggregation,
  getHasAggregation,
  getMultiValue,
  getNewFilterHasAggregation,
  getSortFromOption,
  getSortOptions,
  IFilter,
  IOption,
  mapAggregationOptions,
  mapGradeAggregationOptions,
  mapHexclrgrpAggregationOptions,
  mapFabricFinishTypeAggregationOptions,
  mapPatternAggregationOptions,
  Options,
  Sort,
  SortOption,
} from "../utils/utils";
import FilterItem from "./components/FilterItem";
import FilterList from "./components/FilterList";
import ModalHeaderBack from "./components/ModalHeaderBack";
import SelectPrice from "./components/SelectPrice";
import SwitchFilters, { SwitchFiltersProps } from "./components/SwitchFilters";
import { useMediaQuery } from "react-responsive";
import { useLocation, useHistory } from "react-router-dom";
import SearchField from "./components/searchField";
import SelectQuantity from "./components/SelectQuantity";
import SelectDimension from "./components/SelectDimension";

const initialDrawerWrapperHeight = 100;

type MultiOptions = IOption | Options | null;
export interface FilterProps
  extends Pick<
    SwitchFiltersProps,
    "filter" | "setLaneFilter" | "clearLaneFilter" | "clearFilter"
  > {
  setFabricsCollectionFilter: (
    filter: IFilter["ffifabrics_collection"]
  ) => void;
  setGradeFilter: (filter: IFilter["ffifabrics_grade"]) => void;
  setPatternFilter: (filter: IFilter["ffifabrics_category1"]) => void;
  setHexclrgrpFilter: (filter: IFilter["ffifabrics_hexclrgrp"]) => void;
  setFabricsFinishTypeFilter: (
    filter: IFilter["ffifabrics_finishtype"]
  ) => void;
  setPriceFilter: (price: IFilter["price"]) => void;
  priceAggregation: Maybe<Aggregation>;
  minPrice: number;
  maxPrice: number;
  fromPercentage: number;
  toPercentage: number;
  setFromPercentage: (percentage: number) => void;
  setToPercentage: (percentage: number) => void;
  inFabricsContext: boolean;
  aggregations: Array<Maybe<Aggregation>>;
  gradeOptions: Options;
  patternOptions: Options;
  hexclrgrpOptions: Options;
  setCollectionFilter: (value: string[]) => void;
  setCategoryFilter: (value: any) => void;
  setCustomTypeFilter: (value: string[]) => void;
  toggleIsNewFilter: () => void;
  toggleInStockFilter: () => void;
  toogleReadyToShip: () => void;
  toggleAssemblyFilter: () => void;
  toggleLeatherAvailableFilter: () => void;
  isLoggedIn: boolean;
  sort: Sort;
  setSort: (sort: Sort) => void;
  toggleIsAsc: () => void;
  isCollection: boolean;
  listingProps: any;
  categoryListDropdown: any;
  categoryId: any;
  isSearchPage: any;
  preselectedIndustry: any;
  maxSqFt: number;
  minSqFt: number;
  toSqFtPercentage: number;
  fromSqFtPercentage: number;
  sqFtAggregation: Maybe<Aggregation>;
  setToSqFtPercentage: (percentage: number) => void;
  setFromSqFtPercentage: (percentage: number) => void;
  setSquareFeetFilter: (
    price: IFilter["ffifabrics_avail_filter_bucket"]
  ) => void;
  sizeSuffix: string;
  searchVal: string;
  setSearchVal: (value: string) => void;
  setCurrentPage: (value: number) => void;
  isExact: boolean;
  setExact: (value: boolean) => void;
  categoryName: string;
  isInStock: boolean;
  setQuantityFilter: (
    ffi_qty_filter_bucket: IFilter["ffi_qty_filter_bucket"]
  ) => void;
  setWidthFilter: (
    ffi_width_filter_bucket: IFilter["ffi_width_filter_bucket"]
  ) => void;
  setHeightFilter: (
    ffi_height_filter_bucket: IFilter["ffi_height_filter_bucket"]
  ) => void;
  setDepthFilter: (
    ffi_depth_filter_bucket: IFilter["ffi_depth_filter_bucket"]
  ) => void;
  categoryIds: any[];
  setFinishCategory: any;
  finishCategory: any;
}
const Filter: React.FC<FilterProps> = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [hasNewChangeWithUrl, setHasNewChangeWithUrl] = React.useState(1);
  const [isDrawerOpen, setIsDrawerOpen] = useBoolean(true);
  const [industryFilter, setIndustryFilter] = React.useState({});
  //value of search field before user presses search
  const [unconfirmedSearchVal, setUnconfirmedSearchVal] = React.useState(
    props.searchVal
  );

  const handleSearchBtn = React.useCallback(
    () => props.setSearchVal(unconfirmedSearchVal),
    [props, unconfirmedSearchVal]
  );

  // const [selectedCategory, setSelectedCategory] = React.useState<
  //   | null
  //   | number
  //   | {
  //       value: number;
  //       label: string;
  //     }
  // >(null);

  // React.useEffect(() => {
  //         console.log(props.categoryListDropdown)
  //     console.log(props?.filter)
  //   // @ts-ignore
  //   if (props?.filter?.category_id_product) {

  //     const findCategory = props.categoryListDropdown?.find(
  //       // @ts-ignore
  //       (category) => category?.value === props?.filter?.category_id_product
  //     );
  //     console.log(findCategory)
  //     setSelectedCategory(findCategory);
  //     handleCategoryChange(findCategory);
  //   }
  //   // @ts-ignore
  // }, [props?.filter?.category_id_product, props.categoryListDropdown]);

  const selectedCategory = React.useMemo(() => {
    let found = props?.categoryListDropdown?.find(
      (option: any) => option.value === Number(props.filter.category_id_product)
    );
    return found;
  }, [
    props.categoryListDropdown,
    props.filter.category_id_product,
    location.pathname,
  ]);

  let dimensionWidthFilter = useMemo(
    () => props.filter?.ffi_width_filter_bucket,
    [location.pathname, props.filter?.ffi_width_filter_bucket]
  );

  let dimensionHeightFilter = useMemo(
    () => props.filter?.ffi_height_filter_bucket,
    [location.pathname, props.filter?.ffi_height_filter_bucket]
  );

  let dimensionDepthFilter = useMemo(
    () => props.filter?.ffi_depth_filter_bucket,
    [location.pathname, props.filter?.ffi_depth_filter_bucket]
  );

  const [showFilters, setShowFilters] = React.useState(true);
  const [drawerWrapperHeight, setDrawerWrapperHeight] = React.useState(
    initialDrawerWrapperHeight
  );
  const [categoryOpened, setCategoryOpened] = React.useState(false);
  const [
    resetSelectCategoryOptions,
    setResetSelectCategoryOptions,
  ] = React.useState(false);

  const isInStockProducts = useMemo(() => {
    // @ts-ignore
    return props?.listingProps?.some((item) => item.in_stock_filter);
  }, [props?.listingProps]);

  const isNewSelected = useMemo(
    // @ts-ignore
    () => props?.listingProps?.every((item) => item.is_new),
    [props?.listingProps]
  );

  const showReadyToShip = useMemo(() => {
    let found = props.aggregations.some(
      (aggregation) => aggregation?.attribute_code === "ready_to_ship_filter"
    );
    return found && props.isInStock;
  }, [props.aggregations, props.isInStock]);

  const toggleReadyToShip = useCallback(() => {
    props.setQuantityFilter(null);
    props.toogleReadyToShip();
  }, [props.setQuantityFilter, props.toogleReadyToShip]);

  const drawerRef = React.useRef<HTMLDivElement | null>(null);

  let isMobile = useMediaQuery({ maxWidth: 992 });
  let isTablet = useMediaQuery({ minWidth: 1200 });
  React.useEffect(() => {
    // * when filter updates it may be because a new entry was added to a multi select field
    // * in which case the height may need to be increased to prevent an overflow which conflicts with the product list
    // * the additional height isn't taken into account initially because there is no practical limit to the number of selections
    // * and because it is not visually pleasing
    // * hence an optimal height is set initially and updates are performed dynamically
    if (drawerRef.current) {
      const drawerHeight = drawerRef.current.getBoundingClientRect().height;
      if (drawerHeight > initialDrawerWrapperHeight) {
        setDrawerWrapperHeight(drawerHeight);
      }
    }
  }, [props.filter]);

  React.useEffect(() => {
    let industryFilter = props.aggregations?.find(
      (aggregation) => aggregation?.attribute_code === "industry_filter"
    );
    // @ts-ignore
    setIndustryFilter(industryFilter);
    // return () => {
    //   resetFilters();
    //   window.localStorage.removeItem("industry");
    // };
  }, [props.aggregations]);

  const toggleIsOpen = React.useCallback(() => setIsDrawerOpen.toggle(), [
    setIsDrawerOpen,
  ]);
  const sortOptions = React.useMemo(
    () => getSortOptions(props.isLoggedIn, location),
    [props.isLoggedIn, location]
  );

  const sortValue = React.useMemo(
    () =>
      sortOptions.find((option) => {
        const [key, direction] = option.value.split(",");
        return key === props.sort.key && direction === props.sort.direction;
      }),
    [sortOptions, props.sort]
  );

  const handleSortChange = React.useCallback(
    (option: SortOption) => {
      const [key, direction] = option.value.split(",");
      props.setSort({ key, direction } as Sort);
    },
    [props]
  );

  const [sortModalOpen, setSortModalOpen] = useBoolean();
  const [filterModalOpen, setFilterModalOpen] = useBoolean(false);
  const [selectedFilter, setSelectedFilter] = React.useState(Filters.none);

  const selectLane = React.useCallback(() => {
    setSelectedFilter(Filters.lane);
  }, [setSelectedFilter]);
  const selectCollection = React.useCallback(() => {
    setSelectedFilter(Filters.ffi_collection);
  }, [setSelectedFilter]);
  const selectCustomType = React.useCallback(() => {
    setSelectedFilter(Filters.fficustopt_type_filterable);
  }, [setSelectedFilter]);
  const selectFabricsCollection = React.useCallback(() => {
    setSelectedFilter(Filters.ffifabrics_collection);
  }, [setSelectedFilter]);
  const selectGrade = React.useCallback(() => {
    setSelectedFilter(Filters.ffifabrics_grade);
  }, [setSelectedFilter]);
  const selectPattern = React.useCallback(() => {
    setSelectedFilter(Filters.ffifabrics_category1);
  }, [setSelectedFilter]);
  const selectHexclrgrp = React.useCallback(() => {
    setSelectedFilter(Filters.ffifabrics_hexclrgrp);
  }, [setSelectedFilter]);
  const selectPrice = React.useCallback(() => {
    setSelectedFilter(Filters.price);
  }, [setSelectedFilter]);
  const selectStockAmount = React.useCallback(() => {
    setSelectedFilter(Filters.ffifabrics_avail_filter_bucket);
  }, [setSelectedFilter]);
  const selectQtyAmount = React.useCallback(() => {
    setSelectedFilter(Filters.ffi_qty_filter_bucket);
  }, [setSelectedFilter]);
  const selectDimensions = React.useCallback(
    () => setSelectedFilter(Filters.dimensions),
    [setSelectedFilter]
  );
  const handleBack = React.useCallback(
    () => setSelectedFilter(Filters.none),
    []
  );

  const isAnyFilterActive = (filters: Object) => {
    let atLeastOneActiveFilter = false;
    const filtersValues = Object.values(filters);

    // @ts-ignore
    if (filters?.category_id_product) return true;

    filtersValues.forEach((element) => {
      switch (typeof element) {
        case "string":
          if (element !== "") {
            atLeastOneActiveFilter = true;
          }
          break;
        case "boolean":
          if (element !== false) {
            atLeastOneActiveFilter = true;
          }
          break;
        case "object":
          if (
            element !== null &&
            (element.length || Object.keys(element).length)
          ) {
            atLeastOneActiveFilter = true;
          }
          break;
        default:
          atLeastOneActiveFilter = false;
          break;
      }
    });

    return atLeastOneActiveFilter;
  };
  const handleSaveSelection = React.useCallback(
    () => setFilterModalOpen.setFalse(),
    [setFilterModalOpen]
  );

  const checkForDimensions = useCallback(
    (code) => props.aggregations?.find((item) => item?.attribute_code === code),
    [props.aggregations]
  );

  const handleMultiChange = React.useCallback(
    (setter: (options: IOption["value"][]) => void) => (
      options: MultiOptions
    ) => {
      setter(
        options === null
          ? []
          : Array.isArray(options)
          ? options.find((option) => !option.value)
            ? []
            : options.map((option) => option.value)
          : options.value
          ? [options.value]
          : []
      );
    },
    []
  );
  const [prevCollectionOptions, setPrevCollectionOptions] = React.useState(
    () => {
      return mapAggregationOptions(
        "ffi_collection",
        appendCountToMaybeOptionsLabel,
        props.aggregations
      )[1];
    }
  );
  const [hasCollectionOptions, collectionOptions] = React.useMemo(() => {
    return mapAggregationOptions(
      "ffi_collection",
      appendCountToMaybeOptionsLabel,
      props.aggregations
    );
  }, [props.aggregations]);

  const collectionValue = React.useMemo(() => {
    return getMultiValue(collectionOptions, props.filter.ffi_collection);
  }, [collectionOptions, props, location.pathname]);

  const handleCollectionChange = React.useCallback(
    handleMultiChange(props.setCollectionFilter),
    []
  );

  const [selectedValues, setSelectedValues] = React.useState([
    { value: "", label: "All" },
  ]);

  // @ts-ignore
  const hasNew: number = React.useMemo(() => {
    if (hasNewChangeWithUrl < 2) {
      setHasNewChangeWithUrl(getNewFilterHasAggregation(props.aggregations));
      return getNewFilterHasAggregation(props.aggregations);
    }
  }, [props.aggregations]);

  const hasRetail: boolean = React.useMemo(
    () => getHasAggregation("ffi_laners", props.aggregations),
    [props.aggregations]
  );
  const hasSenior: boolean = React.useMemo(
    () => getHasAggregation("ffi_lanesl", props.aggregations),
    [props.aggregations]
  );
  const hasContract: boolean = React.useMemo(
    () => getHasAggregation("ffi_lanect", props.aggregations),
    [props.aggregations]
  );

  const [
    hasFabricsCollectionOptions,
    fabricsCollectionOptions,
  ] = React.useMemo(() => {
    return mapAggregationOptions(
      "ffifabrics_collection",
      appendCountToMaybeOptionsLabel,
      props.aggregations
    );
  }, [props.aggregations]);

  const fabricsCollectionValue = React.useMemo(
    () =>
      getMultiValue(
        fabricsCollectionOptions,
        props.filter.ffifabrics_collection
      ),
    [fabricsCollectionOptions, props.filter.ffifabrics_collection]
  );
  const handleFabricsCollectionChange = React.useCallback(
    handleMultiChange(props.setFabricsCollectionFilter),
    []
  );
  const handleCategoryChange = React.useCallback(props.setCategoryFilter, []);
  const [hasGradeOptions, gradeOptions] = React.useMemo(
    () =>
      mapAggregationOptions(
        "ffifabrics_grade",
        mapGradeAggregationOptions,
        props.aggregations
      ),
    [props.aggregations]
  );

  const gradeValue = React.useMemo(
    () => getMultiValue(gradeOptions, props.filter.ffifabrics_grade),
    [gradeOptions, props.filter.ffifabrics_grade]
  );
  const handleGradeChange = React.useCallback(
    handleMultiChange(props.setGradeFilter),
    []
  );

  const [hasPatternOptions, patternOptions] = React.useMemo(
    () =>
      mapAggregationOptions(
        "ffifabrics_category1",
        mapPatternAggregationOptions,
        props.aggregations
      ) ?? [],
    [props.aggregations]
  );

  const [patternFilter, setPatternFilter] = React.useState("");
  const filteredPatternOptions = React.useMemo(
    () =>
      patternOptions.filter(
        (option) =>
          !patternFilter ||
          (typeof option.label === "string" &&
            option.label.includes(patternFilter))
      ),
    [patternFilter, patternOptions]
  );
  const handlePatternFilterChange = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setPatternFilter(e.currentTarget.value);
    },
    []
  );
  const patternValue = React.useMemo(
    () => getMultiValue(patternOptions, props.filter.ffifabrics_category1),
    [patternOptions, props.filter.ffifabrics_category1]
  );
  const handlePatternChange = React.useCallback(
    handleMultiChange(props.setPatternFilter),
    []
  );

  const [hasCustomTypeOptions, customTypeOptions] = React.useMemo(
    () =>
      mapAggregationOptions(
        "fficustopt_type_filterable",
        mapHexclrgrpAggregationOptions,
        props.aggregations
      ) ?? [],
    [props.aggregations]
  );

  const customTypeValue = React.useMemo(
    () =>
      getMultiValue(customTypeOptions, props.filter.fficustopt_type_filterable),
    [customTypeOptions, props.filter.fficustopt_type_filterable]
  );

  const handleCustomTypeChange = React.useCallback(
    handleMultiChange(props.setCustomTypeFilter),
    []
  );

  const [hasHexclrgrpOptions, hexclrgrpOptions] = React.useMemo(
    () =>
      mapAggregationOptions(
        "ffifabrics_hexclrgrp",
        mapHexclrgrpAggregationOptions,
        props.aggregations
      ) ?? [],
    [props.aggregations]
  );
  const hexclrgrpValue = React.useMemo(
    () => getMultiValue(hexclrgrpOptions, props.filter.ffifabrics_hexclrgrp),
    [hexclrgrpOptions, props.filter.ffifabrics_hexclrgrp]
  );
  const handleHexclrgrpChange = React.useCallback(
    handleMultiChange(props.setHexclrgrpFilter),
    []
  );

  const [
    hasFabricFinishTypeOptions,
    fabricFinishTypeOptions,
  ] = React.useMemo(
    () =>
      mapAggregationOptions(
        "ffifabrics_finishtype",
        mapFabricFinishTypeAggregationOptions,
        props.aggregations
      ) ?? [],
    [props.aggregations]
  );
  const fabricFinishTypeValue = React.useMemo(
    () =>
      getMultiValue(
        fabricFinishTypeOptions,
        props.filter.ffifabrics_finishtype
      ),
    [fabricFinishTypeOptions, props.filter.ffifabrics_finishtype]
  );
  const handleFabricFinishTypeChange = React.useCallback(
    handleMultiChange(props.setFabricsFinishTypeFilter),
    []
  );

  const [isDragging, setIsDragging] = React.useState<DragTarget>(
    DragTarget.none
  );

  const [initFrom, setInitFrom] = React.useState(false);
  const [initTo, setInitTo] = React.useState(false);

  React.useEffect(() => {
    if (props?.filter?.price?.from !== undefined) setInitFrom(true);
  }, [props?.filter?.price?.from]);

  React.useEffect(() => {
    if (props?.filter?.price?.to !== undefined) setInitTo(true);
  }, [props?.filter?.price?.to]);

  const hasPriceOptions = React.useMemo(
    () =>
      !!props.priceAggregation?.options?.length &&
      props.isLoggedIn &&
      !location.pathname.includes("fabrics-finishes/") &&
      !location.pathname.includes("custom-options") &&
      !(props.isSearchPage && props.categoryName === "fabrics-and-leather"),
    [
      props.priceAggregation,
      props.isLoggedIn,
      props.isSearchPage,
      props.categoryName,
      location.pathname,
    ]
  );

  const fromPrice = React.useMemo(() => {
    if (!initFrom) return props?.filter?.price?.from;
    return percentageToPrice(props.maxPrice, props.fromPercentage);
  }, [props.maxPrice, props.fromPercentage]);

  const toPrice = React.useMemo(() => {
    if (!initTo) return props?.filter?.price?.to;
    return percentageToPrice(props.maxPrice, props.toPercentage);
  }, [props.maxPrice, props.toPercentage]);

  const hasSqFtOptions = React.useMemo(
    () => !!props.sqFtAggregation?.options?.length && props.isLoggedIn,
    [props.sqFtAggregation, props.isLoggedIn]
  );

  const hasQtyOptions = React.useMemo(
    () =>
      // true
      !!getAggregation("ffi_qty_filter_bucket", props.aggregations)?.options
        ?.length &&
      props.filter.ready_to_ship_filter &&
      props.isInStock,
    [props.aggregations, props.filter.ready_to_ship_filter, props.isInStock]
  );
  const fromSqFt = React.useMemo(
    () => percentageToPrice(props.maxSqFt, props.fromSqFtPercentage),
    [props.maxSqFt, props.fromSqFtPercentage]
  );
  const toSqFt = React.useMemo(
    () => percentageToPrice(props.maxSqFt, props.toSqFtPercentage),
    [props.maxSqFt, props.toSqFtPercentage]
  );

  const trackRef = React.useRef<HTMLDivElement | null>(null);
  const handleDragStartFrom = React.useCallback(
    (e) => setIsDragging(DragTarget.from),
    []
  );

  const resetFilters = React.useCallback(() => {
    // setSelectedCategory(null);
    props.clearFilter();
    setResetSelectCategoryOptions(true);
    setTimeout(() => {
      setResetSelectCategoryOptions(false);
    }, 0);
  }, []);

  // React.useEffect(() => {
  //   props.clearFilter();
  // }, [props, props.categoryId]);

  const handleDrag = React.useCallback(
    (e: any) => {
      if (trackRef.current) {
        if (isDragging === DragTarget.from) {
          props.setFromPercentage(
            eventToPercentage({
              minPercentage,
              maxPercentage: props.toPercentage - minimumPercentageRange,
              event: e,
              trackElement: trackRef.current,
            })
          );
        } else if (isDragging === DragTarget.to) {
          props.setToPercentage(
            eventToPercentage({
              minPercentage: props.fromPercentage + minimumPercentageRange,
              maxPercentage,
              event: e,
              trackElement: trackRef.current,
            })
          );
        }
      }
    },
    [isDragging, props]
  );

  const handleSqFtDrag = React.useCallback(
    (e: any) => {
      if (trackRef.current) {
        if (isDragging === DragTarget.from) {
          props.setFromSqFtPercentage(
            eventToPercentage({
              minPercentage,
              maxPercentage: props.toPercentage - minimumPercentageRange,
              event: e,
              trackElement: trackRef.current,
            })
          );
        } else if (isDragging === DragTarget.to) {
          props.setToSqFtPercentage(
            eventToPercentage({
              minPercentage: props.fromPercentage + minimumPercentageRange,
              maxPercentage,
              event: e,
              trackElement: trackRef.current,
            })
          );
        }
      }
    },
    [isDragging, props]
  );

  React.useEffect(() => {
    if (!selectedCategory) {
      setResetSelectCategoryOptions(true);
      setTimeout(() => {
        setResetSelectCategoryOptions(false);
      }, 0);
    }
  }, [selectedCategory]);

  const handleDragStartTo = React.useCallback(
    () => setIsDragging(DragTarget.to),
    []
  );

  const handleDragStop = React.useCallback(() => {
    setIsDragging(DragTarget.none);

    props.setPriceFilter({
      from: percentageToPrice(props.maxPrice, props.fromPercentage),
      to: percentageToPrice(props.maxPrice, props.toPercentage),
    });
  }, [setIsDragging, props]);

  const handleDragStartToSqFt = React.useCallback(
    () => setIsDragging(DragTarget.to),
    []
  );

  const handleDragStopSqFt = React.useCallback(() => {
    setIsDragging(DragTarget.none);

    props.setSquareFeetFilter({
      from: percentageToPrice(props.maxSqFt, props.fromSqFtPercentage),
      to: percentageToPrice(props.maxSqFt, props.toSqFtPercentage),
    });
  }, [setIsDragging, props]);

  const sqFtSliderProps = React.useMemo(() => {
    return {
      className: !isMobile ? "w-100" : "w-75",
      minPrice: props.minSqFt,
      maxPrice: props.maxSqFt,
      fromPrice:
        // @ts-ignore
        +props?.filter?.ffifabrics_avail_filter_bucket?.from ?? fromSqFt,
      // @ts-ignore
      toPrice: props?.filter?.ffifabrics_avail_filter_bucket?.to
        ? +props?.filter?.ffifabrics_avail_filter_bucket?.to
        : toSqFt,
      fromPercentage: props.fromSqFtPercentage,
      toPercentage: props.toSqFtPercentage,
      handleDragStartFrom: handleDragStartFrom,
      handleDragStartTo: handleDragStartTo,
      handleDrag: handleSqFtDrag,
      handleDragStop: handleDragStopSqFt,
    };
  }, [
    fromSqFt,
    toSqFt,
    handleSqFtDrag,
    handleDragStartFrom,
    handleDragStartTo,
    props.fromSqFtPercentage,
    props.toSqFtPercentage,
    props.maxSqFt,
    props.minSqFt,
    handleDragStopSqFt,
    props.filter?.ffifabrics_avail_filter_bucket,
    isMobile,
  ]);
  const sliderProps = React.useMemo(() => {
    return {
      className: !isMobile ? "w-100" : "w-75",
      minPrice: props.minPrice,
      maxPrice: props.maxPrice,
      // @ts-ignore
      fromPrice: fromPrice ?? +props?.filter?.price?.from,
      // @ts-ignore
      toPrice: toPrice ? toPrice : +props?.filter?.price?.to,
      fromPercentage:
        !isAnyFilterActive(props?.filter) && props.fromPercentage
          ? 0
          : props.fromPercentage,
      toPercentage:
        !isAnyFilterActive(props?.filter) && props.toPercentage < 100
          ? 100
          : props.toPercentage,
      handleDragStartFrom: handleDragStartFrom,
      handleDragStartTo: handleDragStartTo,
      handleDrag: handleDrag,
      handleDragStop: handleDragStop,
    };
  }, [
    fromPrice,
    toPrice,
    handleDrag,
    handleDragStartFrom,
    handleDragStartTo,
    props.fromPercentage,
    props.toPercentage,
    props.maxPrice,
    props.minPrice,
    handleDragStop,
    props.filter?.price,
    isMobile,
  ]);

  React.useEffect(() => {
    let filters = [
      hasCollectionOptions,
      hasFabricsCollectionOptions,
      hasGradeOptions,
      hasPatternOptions,
      hasHexclrgrpOptions,
      hasCustomTypeOptions,
      hasRetail,
      hasSenior,
      hasContract,
    ];
    let hideFilters = filters.every((filter) => filter === false);
    if (hideFilters) {
      setShowFilters(false);
    } else {
      setShowFilters(true);
    }
  }, [props]);

  React.useEffect(() => {
    return history.listen((location) => {
      setHasNewChangeWithUrl(1);
    });
  }, [history]);

  const clearDimensionFilter = (label: string, callable: any) => (
    <div
      className="d-flex align-items-center"
      onClick={() => callable(null)}
      onKeyUp={createEnterHandler(() => callable(null))}
      tabIndex={0}
      role="button"
    >
      <MDBTypography className="ml-3 mb-0 text-default fs-14 clear-filters-plp">
        <Icon icon="closeDefault" />
        {label}
      </MDBTypography>
    </div>
  );
  let whatsNew = window.location.href.indexOf("what-s-new") > -1;

  return (
    <MDBRow>
      <MDBCol>
        <MDBRow className={"flex-grow-1 mb-5"}>
          <MDBCol
            lg="8"
            size={(isMobile && "12") || "8"}
            className={`${
              isMobile && "mb-4 justify-content-between"
            } d-flex align-items-center p-xs-0 pl-0`}
          >
            {hasCollectionOptions ||
            hasFabricsCollectionOptions ||
            hasGradeOptions ||
            hasPatternOptions ||
            hasHexclrgrpOptions ||
            hasCustomTypeOptions ||
            hasRetail ||
            hasSenior ||
            hasContract ||
            hasSqFtOptions ||
            hasPriceOptions ? (
              <>
                {!showFilters ? null : (
                  <div
                    className="d-flex align-items-center"
                    onClick={setFilterModalOpen.setTrue}
                    onKeyUp={setFilterModalOpen.setTrue}
                    tabIndex={0}
                    role="button"
                  >
                    {filterModalOpen ? (
                      <Icon icon="filterOn" />
                    ) : (
                      <Icon icon="filterOff" />
                    )}
                    <MDBTypography className="ml-4 mb-0 fs-14 text-default">
                      Filters
                    </MDBTypography>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
            <>
              {(hasNewChangeWithUrl > 1 && !whatsNew) || isNewSelected ? (
                <div
                  className={`d-flex align-items-center text-center plp-new-items-wrapper justify-content-end ${
                    !isMobile && "ml-5"
                  }
                    `}
                >
                  <Switch
                    checked={!!props.filter.is_new}
                    onChange={props.toggleIsNewFilter}
                    label="New Items"
                    // disabled={hasNew <= 1}
                  />
                </div>
              ) : null}

              {/* {props?.categoryIds?.length ? (
                <Select
                  className="ml-4 pl-2 md"
                  options={props?.categoryIds}
                  value={props?.finishCategory}
                  // @ts-ignore
                  onChange={(category) => props?.setFinishCategory(category)}
                />
              ) : (
                <></>
              )} */}
              {!isMobile &&
              isInStockProducts &&
              !window.location.pathname.includes("/instock") ? (
                <div className="stock-wrapper">
                  <Switch
                    style={{ color: "#6C808B" }}
                    className="pl-xs-5 pl-4"
                    checked={!!props.filter.in_stock_filter}
                    onChange={props.toggleInStockFilter}
                    label="In Stock"
                  />
                </div>
              ) : null}
              {!isMobile && showReadyToShip ? (
                <div className="stock-wrapper">
                  <Switch
                    style={{ color: "#6C808B" }}
                    className="pl-xs-5 pl-4"
                    checked={!!props.filter.ready_to_ship_filter}
                    onChange={toggleReadyToShip}
                    label="Ready to ship"
                  />
                </div>
              ) : null}
              {/* {window.location.pathname.includes("/instock") ? (
                      <>
                        <div className="stock-wrapper">
                          <Switch
                            style={{ color: "#6C808B" }}
                            className="pl-xs-5 pl-4"
                            checked={!!props.filter.ffi_leatheravl}
                            onChange={props.toggleLeatherAvailableFilter}
                            label="Available in Leather"
                          />
                        </div>
                        <div className="stock-wrapper">
                          <Switch
                            style={{ color: "#6C808B" }}
                            className="pl-xs-5 pl-4"
                            checked={!!props.filter.ffi_assem_req}
                            onChange={props.toggleAssemblyFilter}
                            label="Assembly Required"
                          />
                        </div>
                      </>
                    ) : null} */}
            </>
            {!isMobile && isAnyFilterActive(props.filter) ? (
              <>
                <div
                  className="flex-grow-0 d-flex justify-content-end mr-3"
                  style={{ marginLeft: "2rem" }}
                >
                  <div
                    style={{ textDecoration: "underline" }}
                    className="d-flex align-items-center"
                    onClick={() => {
                      resetFilters();
                    }}
                    onKeyUp={createEnterHandler(resetFilters)}
                    tabIndex={0}
                    role="button"
                  >
                    <MDBTypography className="ml-3 mb-0 text-default fs-16 clear-filters-plp">
                      Clear Filters
                    </MDBTypography>
                  </div>
                </div>
              </>
            ) : null}
            {!isMobile ? (
              <SearchField
                isExact={props.isExact}
                setExact={props.setExact}
                unconfirmedSearchVal={unconfirmedSearchVal}
                setUnconfirmedSearchVal={setUnconfirmedSearchVal}
                handleSearchBtn={handleSearchBtn}
                isSidebar={false}
                setSearchVal={props.setSearchVal}
                searchVal={props.searchVal}
                // @ts-ignore
                setCurrentPage={props.setCurrentPage}
              />
            ) : null}
          </MDBCol>
          <MDBCol
            size={(isMobile && "6") || "4"}
            className={`sort-products d-flex align-items-center justify-content-end p-xs-0 pr-0  ${
              hasCollectionOptions ||
              hasFabricsCollectionOptions ||
              hasGradeOptions ||
              hasPatternOptions ||
              hasHexclrgrpOptions ||
              hasRetail ||
              hasSenior ||
              hasContract ||
              hasCustomTypeOptions ||
              hasPriceOptions
                ? "has-filters"
                : "no-filters"
            }`}
          >
            <div className="d-none d-md-flex align-items-center">
              <MDBTypography className="mb-0 text-gray-darken-3 fs-14">
                Sort:
              </MDBTypography>
              <Select
                className="ml-4 pl-2 md"
                options={sortOptions}
                value={sortValue}
                onChange={handleSortChange}
              />
              <Icon
                className="ml-4 cursor-pointer"
                icon={
                  props.sort.direction === SortEnum.Asc
                    ? "arrowUp"
                    : "arrowDown"
                }
                onClick={props.toggleIsAsc}
              />
            </div>
            <MDBBtn
              className="d-md-none my-0"
              color="secondary"
              onClick={setSortModalOpen.setTrue}
            >
              Sort
            </MDBBtn>
          </MDBCol>
          {!isMobile ? (
            <MDBCol
              className="d-flex align-items-center justify-content-start filters-listing-plp-wrapper mt-4 p-0"
              lg="12"
            >
              <MDBContainer className="d-flex justify-content-start align-items-center p-0">
                {/*TODO: refactor, make it dynamic */}
                {selectedCategory && (
                  <>
                    <div
                      className="d-flex align-items-center"
                      onClick={() => {
                        props.setCategoryFilter(null);
                        // setSelectedCategory(null);
                      }}
                      onKeyUp={createEnterHandler(() =>
                        props.setCategoryFilter(null)
                      )}
                      tabIndex={0}
                      role="button"
                    >
                      <MDBTypography className="ml-3 mb-0 text-default fs-14 clear-filters-plp">
                        <Icon icon="closeDefault" />
                        {/* @ts-ignore */}
                        {selectedCategory?.label}
                      </MDBTypography>
                    </div>
                  </>
                )}
                {collectionValue.length > 0 && (
                  <>
                    <div
                      className="d-flex align-items-center"
                      onClick={() => props.setCollectionFilter([])}
                      onKeyUp={createEnterHandler(() =>
                        props.setCollectionFilter([])
                      )}
                      tabIndex={0}
                      role="button"
                    >
                      <MDBTypography className="ml-3 mb-0 text-default fs-14 clear-filters-plp">
                        <Icon icon="closeDefault" />
                        {collectionValue[0]?.label}
                      </MDBTypography>
                    </div>
                  </>
                )}
                {dimensionWidthFilter &&
                  clearDimensionFilter(
                    "Dimension Width Filter",
                    props.setWidthFilter
                  )}
                {dimensionHeightFilter &&
                  clearDimensionFilter(
                    "Dimension Height Filter",
                    props.setHeightFilter
                  )}
                {dimensionDepthFilter &&
                  clearDimensionFilter(
                    "Dimension Depth Filter",
                    props.setDepthFilter
                  )}
                {fabricsCollectionValue.length > 0 && (
                  <>
                    <div
                      className="d-flex align-items-center"
                      onClick={() => props.setFabricsCollectionFilter([])}
                      onKeyUp={createEnterHandler(() =>
                        props.setFabricsCollectionFilter([])
                      )}
                      tabIndex={0}
                      role="button"
                    >
                      <MDBTypography className="ml-3 mb-0 text-default fs-14 clear-filters-plp">
                        <Icon icon="closeDefault" />
                        {fabricsCollectionValue[0]?.label}
                      </MDBTypography>
                    </div>
                  </>
                )}{" "}
                {hexclrgrpValue.length > 0 && (
                  <>
                    <div
                      className="d-flex align-items-center"
                      onClick={() => props.setHexclrgrpFilter([])}
                      onKeyUp={createEnterHandler(() =>
                        props.setHexclrgrpFilter([])
                      )}
                      tabIndex={0}
                      role="button"
                    >
                      <MDBTypography className="ml-3 mb-0 text-default fs-14 clear-filters-plp">
                        <Icon icon="closeDefault" />
                        {hexclrgrpValue[0]?.label}
                      </MDBTypography>
                    </div>
                  </>
                )}{" "}
                {customTypeValue.length > 0 && (
                  <>
                    <div
                      className="d-flex align-items-center"
                      onClick={() => props.setCustomTypeFilter([])}
                      onKeyUp={createEnterHandler(() =>
                        props.setCustomTypeFilter([])
                      )}
                      tabIndex={0}
                      role="button"
                    >
                      <MDBTypography className="ml-3 mb-0 text-default fs-14 clear-filters-plp">
                        <Icon icon="closeDefault" />
                        {customTypeValue[0]?.label}
                      </MDBTypography>
                    </div>
                  </>
                )}
                {patternValue.length > 0 && (
                  <>
                    <div
                      className="d-flex align-items-center"
                      onClick={() => props.setPatternFilter([])}
                      onKeyUp={createEnterHandler(() =>
                        props.setPatternFilter([])
                      )}
                      tabIndex={0}
                      role="button"
                    >
                      <MDBTypography className="ml-3 mb-0 text-default fs-14 clear-filters-plp">
                        <Icon icon="closeDefault" />
                        {patternValue[0]?.label}
                      </MDBTypography>
                    </div>
                  </>
                )}{" "}
                {gradeValue.length > 0 && (
                  <>
                    <div className="flex-grow-0 d-flex justify-content-end">
                      <div
                        className="d-flex align-items-center"
                        onClick={() => props.setGradeFilter([])}
                        onKeyUp={createEnterHandler(() =>
                          props.setGradeFilter([])
                        )}
                        tabIndex={0}
                        role="button"
                      >
                        <MDBTypography className="ml-3 mb-0 text-default fs-14 clear-filters-plp">
                          <Icon icon="closeDefault" />
                          {gradeValue[0]?.label}
                        </MDBTypography>
                      </div>
                    </div>
                  </>
                )}
                {!!props.filter.is_new && (
                  <>
                    <div className="flex-grow-0 d-flex justify-content-end">
                      <div
                        className="d-flex align-items-center"
                        onClick={props.toggleIsNewFilter}
                        onKeyUp={props.toggleIsNewFilter}
                        tabIndex={0}
                        role="button"
                      >
                        <MDBTypography className="ml-3 mb-0 text-default fs-14 clear-filters-plp">
                          <Icon icon="closeDefault" />
                          {"Only New Items"}
                        </MDBTypography>
                      </div>
                    </div>
                  </>
                )}
              </MDBContainer>
            </MDBCol>
          ) : (
            <></>
          )}
          {isMobile ? (
            <MDBCol
              className="d-flex align-items-center justify-content-end mt-4"
              size="12"
            >
              {isAnyFilterActive(props.filter) && (
                <div className="flex-grow-0 d-flex justify-content-end">
                  <div
                    className="d-flex align-items-center"
                    onClick={resetFilters}
                    onKeyUp={createEnterHandler(resetFilters)}
                    tabIndex={0}
                    role="button"
                    style={{ textDecoration: "underline" }}
                  >
                    <MDBTypography className="ml-3 mb-0 text-default fs-14 clear-filters-plp">
                      Clear Filters
                    </MDBTypography>
                  </div>
                </div>
              )}
            </MDBCol>
          ) : (
            <></>
          )}
        </MDBRow>
        <Modal
          isOpen={sortModalOpen}
          onClose={setSortModalOpen.setFalse}
          title="Sort"
        >
          {sortOptions.map((option) => {
            const handleClick = () => {
              props.setSort(getSortFromOption(option));
              setSortModalOpen.setFalse();
            };
            return (
              <div
                key={option.value}
                className="px-5 py-4 border-bottom"
                onClick={handleClick}
                onKeyUp={createEnterHandler(handleClick)}
                tabIndex={0}
                role="button"
              >
                <MDBTypography className="m-0 fs-14 fwregular">
                  {option.label}
                </MDBTypography>
              </div>
            );
          })}
        </Modal>
        {isMobile ? (
          <Modal
            isOpen={filterModalOpen}
            onClose={setFilterModalOpen.setFalse}
            title="All Filters"
          ></Modal>
        ) : null}
        <MDBModal
          inline={false}
          noClickableBodyWithoutBackdrop
          overflowScroll={false}
          backdropClassName="d-none"
          className="m-0 ml-auto h-100"
          // contentClassName="h-100"
          id="filter-modal"
          isOpen={filterModalOpen}
          toggle={setFilterModalOpen.setFalse}
        >
          <MDBModalBody className="p-0 h-100 d-flex flex-column filter-modal">
            {categoryOpened ? (
              <>
                <ModalHeaderBack onBack={() => setCategoryOpened(false)}>
                  Category
                </ModalHeaderBack>
                <FilterList
                  filterValue={
                    // @ts-ignore
                    selectedCategory?.value ? [selectedCategory?.value] : []
                  }
                  options={props.categoryListDropdown}
                  setFilterValue={handleCategoryChange}
                  onSaveSelection={handleSaveSelection}
                  isCategory={true}
                  // setSelectedCategory={setSelectedCategory}
                />
              </>
            ) : null}
            {(() => {
              switch (selectedFilter) {
                // case Filters.lane:
                //   return (
                //     <>
                //       <ModalHeaderBack onBack={handleBack}>
                //         Industry
                //       </ModalHeaderBack>
                //       <SwitchFilters
                //         hasRetail={hasRetail}
                //         hasSenior={hasSenior}
                //         hasContract={hasContract}
                //         selectedValues={selectedValues}
                //         setSelectedValues={setSelectedValues}
                //         filter={props.filter}
                //         clearFilter={resetFilters}
                //         setLaneFilter={props.setLaneFilter}
                //         clearLaneFilter={props.clearLaneFilter}
                //         listingProps={props.listingProps}
                //         // @ts-ignore
                //         industryFilter={industryFilter}
                //         vertical
                //         onSaveSelection={handleSaveSelection}
                //       />
                //     </>
                //   );
                // case Filters.ffifabrics_collection:
                //   return (
                //     <>
                //       <ModalHeaderBack onBack={handleBack}>
                //         Collection
                //       </ModalHeaderBack>
                //       <FilterList
                //         filterValue={props.filter.ffifabrics_collection}
                //         options={fabricsCollectionOptions}
                //         setFilterValue={props.setFabricsCollectionFilter}
                //         onSaveSelection={handleSaveSelection}
                //       />
                //     </>
                //   );
                case Filters.ffifabrics_grade:
                  return (
                    <>
                      <ModalHeaderBack onBack={handleBack}>
                        Grade
                      </ModalHeaderBack>
                      <FilterList
                        filterValue={props.filter.ffifabrics_grade}
                        options={gradeOptions}
                        setFilterValue={props.setGradeFilter}
                        onSaveSelection={handleSaveSelection}
                      />
                    </>
                  );
                case Filters.ffifabrics_category1:
                  return (
                    <>
                      <ModalHeaderBack onBack={handleBack}>
                        Pattern
                      </ModalHeaderBack>
                      <FilterList
                        filterValue={props.filter.ffifabrics_category1}
                        options={filteredPatternOptions}
                        setFilterValue={props.setPatternFilter}
                        onSaveSelection={handleSaveSelection}
                        // search={
                        //   <Label
                        //     text="Filter by label"
                        //     required={false}
                        //     className="mx-4"
                        //   >
                        //     <MDBInput
                        //       className="mb-0"
                        //       value={patternFilter}
                        //       onChange={handlePatternFilterChange}
                        //       hint="ex: 1100"
                        //     />
                        //   </Label>
                        // }
                      />
                    </>
                  );
                case Filters.ffifabrics_hexclrgrp:
                  return (
                    <>
                      <ModalHeaderBack onBack={handleBack}>
                        Color
                      </ModalHeaderBack>
                      <FilterList
                        filterValue={props.filter.ffifabrics_hexclrgrp}
                        options={hexclrgrpOptions}
                        setFilterValue={props.setHexclrgrpFilter}
                        onSaveSelection={handleSaveSelection}
                      />
                    </>
                  );
                case Filters.fficustopt_type_filterable:
                  return (
                    <>
                      <ModalHeaderBack onBack={handleBack}>
                        Type
                      </ModalHeaderBack>
                      <FilterList
                        filterValue={props.filter.fficustopt_type_filterable}
                        options={customTypeOptions}
                        setFilterValue={props.setCustomTypeFilter}
                        onSaveSelection={handleSaveSelection}
                      />
                    </>
                  );
                case Filters.price:
                  return (
                    <>
                      <ModalHeaderBack onBack={handleBack}>
                        Price
                      </ModalHeaderBack>

                      <div
                        className="mt-5 mb-3 d-flex justify-content-center"
                        onMouseUp={handleDragStop}
                        onMouseLeave={handleDragStop}
                        onMouseMove={handleDrag}
                        // @ts-ignore
                        onTouchMove={handleDrag}
                        // @ts-ignore
                        onTouchEnd={handleDragStop}
                        tabIndex={0}
                        role="button"
                      >
                        <PriceSlider ref={trackRef} {...sliderProps} />
                      </div>
                    </>
                  );
                case Filters.ffifabrics_avail_filter_bucket:
                  return (
                    <>
                      <ModalHeaderBack onBack={handleBack}>
                        Stock amount
                      </ModalHeaderBack>

                      <div
                        className="mt-5 mb-3 d-flex justify-content-center"
                        onMouseUp={handleDragStopSqFt}
                        onMouseLeave={handleDragStopSqFt}
                        onMouseMove={handleSqFtDrag}
                        // @ts-ignore
                        onTouchMove={handleSqFtDrag}
                        // @ts-ignore
                        onTouchEnd={handleDragStopSqFt}
                        tabIndex={0}
                        role="button"
                      >
                        <PriceSlider
                          ref={trackRef}
                          {...sqFtSliderProps}
                          sizeSuffix={props?.sizeSuffix}
                          isUniversal={props.isSearchPage}
                        />
                      </div>
                    </>
                  );
                case Filters.ffi_qty_filter_bucket:
                  return (
                    <>
                      <ModalHeaderBack onBack={handleBack}>
                        Stock amount
                      </ModalHeaderBack>
                      <SelectQuantity
                        filter={props.filter}
                        aggregations={props.aggregations}
                        setQuantityFilter={props.setQuantityFilter}
                        isMobile={true}
                      />
                    </>
                  );
                case Filters.dimensions:
                  return (
                    <>
                      <ModalHeaderBack onBack={handleBack}>
                        Dimensions
                      </ModalHeaderBack>
                      {!pathnameIncludes("fabrics") && (
                        <SelectDimension
                          filter={props.filter}
                          aggregations={props.aggregations}
                          setDimensionFilter={props.setWidthFilter}
                          isMobile={true}
                          dimension="ffi_width_filter_bucket"
                        />
                      )}
                      {!pathnameIncludes("fabrics") && (
                        <SelectDimension
                          filter={props.filter}
                          aggregations={props.aggregations}
                          setDimensionFilter={props.setHeightFilter}
                          isMobile={true}
                          dimension="ffi_height_filter_bucket"
                        />
                      )}
                      {!pathnameIncludes("fabrics") && (
                        <SelectDimension
                          filter={props.filter}
                          aggregations={props.aggregations}
                          setDimensionFilter={props.setDepthFilter}
                          isMobile={true}
                          dimension="ffi_depth_filter_bucket"
                        />
                      )}
                    </>
                  );
                case Filters.ffi_collection:
                  return (
                    <>
                      <ModalHeaderBack onBack={handleBack}>
                        Collection
                      </ModalHeaderBack>
                      <FilterList
                        filterValue={props.filter.ffi_collection}
                        options={collectionOptions}
                        setFilterValue={props.setCollectionFilter}
                        onSaveSelection={handleSaveSelection}
                      />
                      {/* <div className="m-4 p-2">
                        <MDBBtn
                          className="m-0 w-100"
                          onClick={handleSaveSelection}
                        >
                          Save Selection
                        </MDBBtn>
                      </div> */}
                    </>
                  );
                default:
                  return !categoryOpened ? (
                    <>
                      <ModalHeader onClose={setFilterModalOpen.setFalse}>
                        All Filters
                      </ModalHeader>
                      {!isMobile ? (
                        hasCollectionOptions ||
                        hasFabricsCollectionOptions ||
                        hasGradeOptions ||
                        hasPatternOptions ||
                        hasHexclrgrpOptions ||
                        hasRetail ||
                        hasSenior ||
                        hasContract ||
                        hasCustomTypeOptions ||
                        hasPriceOptions ||
                        window.location.pathname.includes("/instock") ? (
                          <>
                            {!props.isCollection ? (
                              <div
                                ref={drawerRef}
                                className={`content-wrapper w-100 d-flex align-items-center flex-column`}
                              >
                                {
                                  <>
                                    {(window.location.pathname.includes(
                                      "/instock"
                                    ) ||
                                      (props.isSearchPage &&
                                        props.categoryListDropdown?.length >
                                          0)) &&
                                    !resetSelectCategoryOptions ? (
                                      <Select
                                        placeholder="Category"
                                        className="md mr-4"
                                        options={props.categoryListDropdown}
                                        // @ts-ignore
                                        onChange={(e) => {
                                          // setSelectedCategory(e);
                                          handleCategoryChange(e);
                                        }}
                                        value={selectedCategory}
                                        isSearchable={true}
                                      />
                                    ) : null}
                                    {hasCollectionOptions && (
                                      <Select
                                        // isMulti
                                        // wrapperClass="multi"
                                        placeholder="Collection"
                                        className="md mr-4"
                                        value={collectionValue}
                                        options={collectionOptions}
                                        onChange={handleCollectionChange}
                                        isSearchable={true}
                                      />
                                    )}
                                    {(hasRetail || hasSenior || hasContract) &&
                                      +!location.pathname.includes(
                                        "/industry"
                                      ) && (
                                        <SwitchFilters
                                          hasRetail={hasRetail}
                                          selectedValues={selectedValues}
                                          setSelectedValues={setSelectedValues}
                                          hasSenior={hasSenior}
                                          hasContract={hasContract}
                                          filter={props.filter}
                                          clearFilter={resetFilters}
                                          setLaneFilter={props.setLaneFilter}
                                          clearLaneFilter={
                                            props.clearLaneFilter
                                          }
                                          vertical={false}
                                          listingProps={props.listingProps}
                                          // @ts-ignore
                                          industryFilter={industryFilter}
                                          // className="mt-4"
                                        />
                                      )}
                                    {!pathnameIncludes("fabrics") &&
                                      (checkForDimensions(
                                        "ffi_width_filter_bucket"
                                      ) ||
                                        checkForDimensions(
                                          "ffi_height_filter_bucket"
                                        ) ||
                                        checkForDimensions(
                                          "ffi_depth_filter_bucket"
                                        )) && (
                                        <div className="w-100">
                                          <h4 className="mb-2">Dimensions</h4>
                                          {checkForDimensions(
                                            "ffi_width_filter_bucket"
                                          ) && (
                                            <SelectDimension
                                              filter={props.filter}
                                              aggregations={props.aggregations}
                                              setDimensionFilter={
                                                props.setWidthFilter
                                              }
                                              isMobile={true}
                                              dimension="ffi_width_filter_bucket"
                                            />
                                          )}
                                          {checkForDimensions(
                                            "ffi_height_filter_bucket"
                                          ) && (
                                            <SelectDimension
                                              filter={props.filter}
                                              aggregations={props.aggregations}
                                              setDimensionFilter={
                                                props.setHeightFilter
                                              }
                                              isMobile={true}
                                              dimension="ffi_height_filter_bucket"
                                            />
                                          )}
                                          {checkForDimensions(
                                            "ffi_depth_filter_bucket"
                                          ) && (
                                            <SelectDimension
                                              filter={props.filter}
                                              aggregations={props.aggregations}
                                              setDimensionFilter={
                                                props.setDepthFilter
                                              }
                                              isMobile={true}
                                              dimension="ffi_depth_filter_bucket"
                                            />
                                          )}
                                        </div>
                                      )}
                                    {hasFabricsCollectionOptions && (
                                      <Select
                                        // isMulti
                                        // wrapperClass="multi"
                                        placeholder="Collection"
                                        className="md mr-4"
                                        value={fabricsCollectionValue}
                                        options={fabricsCollectionOptions}
                                        onChange={handleFabricsCollectionChange}
                                        isSearchable={true}
                                      />
                                    )}

                                    {(hasGradeOptions ||
                                      hasPatternOptions ||
                                      hasHexclrgrpOptions) && (
                                      <>
                                        {hasGradeOptions &&
                                          (location.pathname.includes(
                                            "/fabric"
                                          ) ||
                                            props.isSearchPage) && (
                                            <Select
                                              // isMulti
                                              // wrapperClass="multi"
                                              placeholder="Grade"
                                              className="md mr-4"
                                              value={gradeValue}
                                              options={gradeOptions}
                                              onChange={handleGradeChange}
                                              isSearchable={false}
                                            />
                                          )}
                                        {hasPatternOptions &&
                                          location.pathname.includes(
                                            "/fabric"
                                          ) && (
                                            <Select
                                              // isMulti
                                              // wrapperClass="multi"
                                              placeholder="Pattern"
                                              className="md mr-4"
                                              value={patternValue}
                                              options={patternOptions}
                                              onChange={handlePatternChange}
                                              isSearchable={false}
                                            />
                                          )}
                                        {hasHexclrgrpOptions && (
                                          <Select
                                            // isMulti
                                            // wrapperClass="multi"
                                            placeholder="Color"
                                            className="md mr-4"
                                            value={hexclrgrpValue}
                                            options={hexclrgrpOptions}
                                            onChange={handleHexclrgrpChange}
                                            isSearchable={false}
                                          />
                                        )}

                                        {hasFabricFinishTypeOptions && (
                                          <Select
                                            // isMulti
                                            // wrapperClass="multi"
                                            placeholder="Finish"
                                            className="md mr-4"
                                            value={fabricFinishTypeValue}
                                            options={fabricFinishTypeOptions}
                                            onChange={
                                              handleFabricFinishTypeChange
                                            }
                                            isSearchable={false}
                                          />
                                        )}

                                        {hasSqFtOptions &&
                                          (location.pathname.includes(
                                            "/fabric"
                                          ) ||
                                            props.isSearchPage) && (
                                            <div className="stock-amount-wrapper">
                                              <SelectPrice
                                                ref={trackRef}
                                                {...sqFtSliderProps}
                                                sizeSuffix={props?.sizeSuffix}
                                                placeholder="Stock amount"
                                                isUniversal={props.isSearchPage}
                                              />
                                            </div>
                                          )}
                                      </>
                                    )}
                                    {hasQtyOptions && (
                                      <SelectQuantity
                                        filter={props.filter}
                                        aggregations={props.aggregations}
                                        setQuantityFilter={
                                          props.setQuantityFilter
                                        }
                                        isMobile={true}
                                      />
                                    )}
                                    {hasCustomTypeOptions ? (
                                      <Select
                                        // isMulti
                                        // wrapperClass="multi"
                                        placeholder="Type"
                                        className="md mr-4"
                                        value={customTypeValue}
                                        options={customTypeOptions}
                                        onChange={handleCustomTypeChange}
                                        isSearchable={false}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                }
                                {hasPriceOptions && (
                                  <div className="w-100">
                                    <h4 className="mb-2">Price</h4>
                                    <div
                                      className="mt-5 w-100 mb-3 d-flex justify-content-center"
                                      onMouseUp={handleDragStop}
                                      onMouseMove={handleDrag}
                                      // @ts-ignore
                                      onTouchMove={handleDrag}
                                      // @ts-ignore
                                      onTouchEnd={handleDragStop}
                                      tabIndex={0}
                                      role="button"
                                    >
                                      <PriceSlider
                                        ref={trackRef}
                                        {...sliderProps}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div
                                ref={drawerRef}
                                className={`content-wrapper w-100 d-flex align-items-center`}
                              >
                                {
                                  <>
                                    {(hasRetail || hasSenior || hasContract) &&
                                      +!location.pathname.includes(
                                        "/industry"
                                      ) && (
                                        <SwitchFilters
                                          hasRetail={hasRetail}
                                          hasSenior={hasSenior}
                                          hasContract={hasContract}
                                          selectedValues={selectedValues}
                                          setSelectedValues={setSelectedValues}
                                          filter={props.filter}
                                          clearFilter={resetFilters}
                                          setLaneFilter={props.setLaneFilter}
                                          clearLaneFilter={
                                            props.clearLaneFilter
                                          }
                                          vertical={false}
                                          listingProps={props.listingProps}
                                          // @ts-ignore
                                          industryFilter={industryFilter}
                                          // className="mt-4"
                                        />
                                      )}
                                  </>
                                }
                              </div>
                            )}
                          </>
                        ) : (
                          <></>
                        )
                      ) : !props.isCollection ? (
                        <>
                          {(window.location.pathname.includes("/instock") ||
                            (props.isSearchPage &&
                              props.categoryListDropdown?.length > 0)) && (
                            <FilterItem
                              onSelect={() => setCategoryOpened(true)}
                            >
                              Category
                            </FilterItem>
                          )}
                          {/* {(hasRetail || hasSenior || hasContract) &&
                          !location.pathname.includes("/industry") ? (
                            <FilterItem onSelect={selectLane}>
                              Industry
                            </FilterItem>
                          ) : null} */}
                          {hasCollectionOptions && (
                            <FilterItem onSelect={selectCollection}>
                              Collection
                            </FilterItem>
                          )}
                          {hasFabricsCollectionOptions && (
                            <FilterItem onSelect={selectFabricsCollection}>
                              Collection
                            </FilterItem>
                          )}
                          {hasGradeOptions && (
                            <FilterItem onSelect={selectGrade}>
                              Grade
                            </FilterItem>
                          )}
                          {hasPatternOptions && (
                            <FilterItem onSelect={selectPattern}>
                              Pattern
                            </FilterItem>
                          )}
                          {hasHexclrgrpOptions && (
                            <FilterItem onSelect={selectHexclrgrp}>
                              Color
                            </FilterItem>
                          )}
                          {hasCustomTypeOptions && (
                            <FilterItem onSelect={selectCustomType}>
                              Type
                            </FilterItem>
                          )}
                          {hasSqFtOptions && (
                            <FilterItem onSelect={selectStockAmount}>
                              Stock amount
                            </FilterItem>
                          )}
                          {hasQtyOptions && (
                            <FilterItem onSelect={selectQtyAmount}>
                              Stock amount
                            </FilterItem>
                          )}
                          {hasPriceOptions && (
                            <FilterItem onSelect={selectPrice}>
                              Price
                            </FilterItem>
                          )}
                          {!pathnameIncludes("fabrics") &&
                            (checkForDimensions("ffi_width_filter_bucket") ||
                              checkForDimensions("ffi_height_filter_bucket") ||
                              checkForDimensions(
                                "ffi_depth_filter_bucket"
                              )) && (
                              <FilterItem onSelect={selectDimensions}>
                                Dimensions
                              </FilterItem>
                            )}
                        </>
                      ) : (
                        <>
                          {/* {(hasRetail || hasSenior || hasContract) && (
                            <FilterItem onSelect={selectLane}>
                              Industry
                            </FilterItem>
                          )} */}
                          {hasPriceOptions && (
                            <FilterItem onSelect={selectPrice}>
                              Price
                            </FilterItem>
                          )}
                        </>
                      )}
                    </>
                  ) : null;
              }
            })()}
            {isMobile && (
              <div className="d-flex mt-4">
                {hasNewChangeWithUrl > 1 && !whatsNew && (
                  <Switch
                    style={{ color: "#6C808B" }}
                    className="pl-xs-5 pl-4"
                    checked={!!props.filter.is_new}
                    onChange={props.toggleIsNewFilter}
                    label="New"
                    // disabled={hasNew <= 1}
                  />
                )}
                {isInStockProducts &&
                selectedFilter !== Filters.ffifabrics_avail_filter_bucket &&
                !window.location.pathname.includes("/instock") ? (
                  <Switch
                    style={{ color: "#6C808B" }}
                    className="pl-xs-5 pl-4"
                    checked={!!props.filter.in_stock_filter}
                    onChange={props.toggleInStockFilter}
                    label="In Stock"
                  />
                ) : null}
                {showReadyToShip && selectedFilter === Filters.none ? (
                  <Switch
                    style={{ color: "#6C808B" }}
                    className="pl-xs-5 pl-4"
                    checked={!!props.filter.ready_to_ship_filter}
                    onChange={toggleReadyToShip}
                    label="Ready to ship"
                  />
                ) : null}
                {/* {window.location.pathname.includes("/instock") ? (
                <>
                  <Switch
                    style={{ color: "#6C808B" }}
                    className="pl-xs-5 pl-4"
                    checked={!!props.filter.ffi_leatheravl}
                    onChange={props.toggleLeatherAvailableFilter}
                    label="Available in Leather"
                  />
                  <Switch
                    style={{ color: "#6C808B" }}
                    className="pl-xs-5 pl-4"
                    checked={!!props.filter.ffi_assem_req}
                    onChange={props.toggleAssemblyFilter}
                    label="Assembly Required"
                  />
                </>
              ) : null} */}
              </div>
            )}
            {isMobile &&
            (props.isSearchPage || props?.categoryIds?.length) &&
            !categoryOpened &&
            selectedFilter === Filters.none ? (
              <div className="px-4">
                <SearchField
                  isExact={props.isExact}
                  setExact={props.setExact}
                  unconfirmedSearchVal={unconfirmedSearchVal}
                  setUnconfirmedSearchVal={setUnconfirmedSearchVal}
                  handleSearchBtn={handleSearchBtn}
                  isSidebar={true}
                  setSearchVal={props.setSearchVal}
                  searchVal={props.searchVal}
                  // @ts-ignore
                  setCurrentPage={props.setCurrentPage}
                />
              </div>
            ) : null}
          </MDBModalBody>
        </MDBModal>
      </MDBCol>
    </MDBRow>
  );
};

export default Filter;
