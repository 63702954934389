import React, { useEffect } from "react";
import PageParser from "core/components/cmsBlock/Parser";
import Breadcrumbs from "app/layout/breadcrumbs";
import SeoHelmet from "app/layout/seoHelmet";

const CmsPage = (props) => {
  let cmsPage = props.data.cmsPage;
  let id = props.id;
  let data = props.data;
  let isCached = props.isCached;
  let url = props.url;
  let addQueryData = props.addQueryData;

  if (props.isCached) cmsPage = props.isCached.data;

  useEffect(() => {
    if (!isCached && typeof url !== "undefined") {
      addQueryData(data.cmsPage, url, id);
    }
  }, [isCached, data, url, id, addQueryData]);

  if (typeof cmsPage === "undefined") {
    return "undefined";
  }

  if (!props.dangHtml) {
    return (
      <>
        {data.title && <SeoHelmet title={data.title} url={data.url_key} />}
        <div className={`page-main-content page-${props.id}`}>
          <div id={"cmspage-" + props.id}>
            <Breadcrumbs />
            {props.title && <h3>{cmsPage.title}</h3>}
            <PageParser data={cmsPage} />
          </div>
        </div>
      </>
    );
  }
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: cmsPage.content
          ?.replaceAll("&lt;", "<")
          ?.replaceAll("&gt;", ">")
          ?.replaceAll("&amp;nbsp;", "<br>"),
      }}
    />
  );
};

export default CmsPage;
