import React from "react";
import Footer from "./footer";

const FooterStateContainer = (props) => {
  let categoryFooter = props.data.categoryList[0];

  return <Footer {...props} categoryFooter={categoryFooter} />;
};

export default FooterStateContainer;
