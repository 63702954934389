import React, { FC, useState, useCallback, useRef, useMemo } from "react";
import SelectPrice from "./SelectPrice";
import {
  DragTarget,
  eventToPercentage,
  priceToPercentage,
  percentageToPrice,
} from "app/pages/catalog/category/utils/price";
import { getAggregation } from "app/pages/catalog/category/utils/utils";
import PriceSlider from "app/layout/PriceSlider/PriceSlider";

export interface SelectQuantityProps {
  filter: any;
  aggregations: any;
  setQuantityFilter: (value: any) => void;
  isMobile: boolean;
}

const SelectQuantity: FC<SelectQuantityProps> = (props) => {
  const { filter, aggregations, setQuantityFilter, isMobile } = props;
  const minimumPercentageRange = 10;
  const minPercentage = 0;
  const maxPercentage = 100;
  const trackRef = useRef<HTMLDivElement | null>(null);
  const [isDragging, setIsDragging] = useState<DragTarget>(DragTarget.none);

  const qtyAggregation = React.useMemo(() => {
    return getAggregation("ffi_qty_filter_bucket", aggregations);
  }, [aggregations]);
  const minQty: number = useMemo(() => {
    if (qtyAggregation) {
      let mP = 0;
      return Math.min(
        mP,
        parseInt(qtyAggregation?.options?.[0]?.value.split("_")?.[0] || "")
      );
    } else return 0;
  }, [qtyAggregation]);
  const maxQty: number = useMemo(() => {
    const options = qtyAggregation?.options;
    if (options) {
      let mP = 0;
      return Math.max(
        mP,
        parseInt(options?.[options.length - 1]?.value.split("_")?.[1] || "")
      );
    } else return 0;
  }, [qtyAggregation]);

  //   console.log(qtyAggregation)
  const [fromPercentage, setFromPercentage] = useState(() =>
    filter.ffi_qty_filter_bucket?.from
      ? priceToPercentage(maxQty, filter.ffi_qty_filter_bucket.from)
      : minPercentage
  );
  const [toPercentage, setToPercentage] = useState(
    filter.ffi_qty_filter_bucket?.from
      ? priceToPercentage(maxQty, filter.ffi_qty_filter_bucket.to)
      : maxPercentage
  );

  const handleDragStartFrom = useCallback((e) => {
    setIsDragging(DragTarget.from);
  }, []);

  const handleDragStartTo = React.useCallback(() => {
    setIsDragging(DragTarget.to);
  }, []);

  const handleDragStop = React.useCallback(() => {
    setIsDragging(DragTarget.none);
    setQuantityFilter({
      from: percentageToPrice(maxQty, fromPercentage),
      to: percentageToPrice(maxQty, toPercentage),
    });
  }, [setQuantityFilter, maxQty, fromPercentage, toPercentage]);
  const handleDrag = useCallback(
    (e: any) => {
      if (trackRef.current) {
        if (isDragging === DragTarget.from) {
          setFromPercentage(
            eventToPercentage({
              minPercentage,
              maxPercentage: toPercentage - minimumPercentageRange,
              event: e,
              trackElement: trackRef.current,
            })
          );
        } else if (isDragging === DragTarget.to) {
          setToPercentage(
            eventToPercentage({
              minPercentage: fromPercentage + minimumPercentageRange,
              maxPercentage,
              event: e,
              trackElement: trackRef.current,
            })
          );
        }
      }
    },
    [fromPercentage, isDragging, toPercentage]
  );

  const fromQty = React.useMemo(() => {
    return percentageToPrice(maxQty, fromPercentage);
  }, [maxQty, fromPercentage]);

  const toQty = React.useMemo(() => {
    return percentageToPrice(maxQty, toPercentage);
  }, [maxQty, toPercentage]);

  const qtySliderProps = React.useMemo(() => {
    return {
      className: "w-100",
      minPrice: minQty,
      maxPrice: maxQty,
      // @ts-ignore
      fromPrice: +props?.filter?.ffi_qty_filter_bucket?.from ?? fromQty,
      // @ts-ignore
      toPrice: props?.filter?.ffi_qty_filter_bucket?.to
        ? +props?.filter?.ffi_qty_filter_bucket?.to
        : toQty,
      fromPercentage: fromPercentage,
      toPercentage: toPercentage,
      handleDragStartFrom: handleDragStartFrom,
      handleDragStartTo: handleDragStartTo,
      handleDrag: handleDrag,
      handleDragStop: handleDragStop,
    };
  }, [
    minQty,
    maxQty,
    props,
    fromQty,
    toQty,
    fromPercentage,
    toPercentage,
    handleDragStartFrom,
    handleDragStartTo,
    handleDrag,
    handleDragStop,
  ]);

  return isMobile ? (
    <div className="w-100">
      <h4 className="mb-2">Stock Amount</h4>
      <div
        className="mt-5 mb-3 d-flex justify-content-center"
        onMouseUp={handleDragStop}
        onMouseLeave={handleDragStop}
        onMouseMove={handleDrag}
        // @ts-ignore
        onTouchMove={handleDrag}
        // @ts-ignore
        onTouchEnd={handleDragStop}
        tabIndex={0}
        role="button"
      >
        <PriceSlider ref={trackRef} {...qtySliderProps} sizeSuffix={""} />
      </div>
    </div>
  ) : (
    <SelectPrice
      ref={trackRef}
      placeholder="Stock amount"
      {...qtySliderProps}
      sizeSuffix={""}
    />
  );
};

export default SelectQuantity;
