import gql from "graphql-tag";

export const PRODUCT = gql`
  query products($filter: ProductAttributeFilterInput!, $search: String) {
    products(filter: $filter, search: $search, pageSize: 10, currentPage: 1) {
      aggregations {
        attribute_code
        options {
          count
          label
          value
        }
        label
        count
      }
      items {
        categories {
          id
          name
        }
        id
        uid
        name
        sku
        ffi_extdesc2
        ffi_name
        url_key
        ffi_riser
        ffifinish_fairshield
        ffifabrics_std_width
        ffifabrics_vrepeat
        ffifabrics_hrepeat
        ffifabrics_fullcont
        ffifabrics_fabric
        colorways: ffifabrics_colorways
        abrasion: ffifabrics_abrasion
        country: ffifabrics_country
        category1: ffifabrics_category1
        collection_fabric: ffifabrics_collection
        pattern: ffifabrics_pattern
        grade: ffifabrics_grade
        color: ffifabrics_descr
        standard_description: ffifabrics_descript
        description {
          html
        }
        meta_description
        meta_title
        image {
          url(width: 700, height: 700)
          label
          disabled
        }
        media_gallery {
          url(width: 700, height: 700)
          label
          types
        }
        small_image {
          url(width: 265, height: 265)
          label
          disabled
        }
        attribute_set_id
        attribute_set_name
        crosssell_products {
          attribute_set_id
          attribute_set_name
          name
          image {
            label
            position
            url(width: 700, height: 700)
          }
          url_key
        }

        related_products {
          id
          sku
          name
          url_key
          ffi_lanect
          ffi_laners
          ffi_lanesl
          ffi_isvisible
          is_new
          description {
            html
          }
          image {
            url(width: 700, height: 700)
            label
            disabled
          }
          small_image {
            url(width: 265, height: 265)
            label
            disabled
          }
          ffi_finishopt
          ffi_frmdimw
          ffi_frmdimh
          price_range {
            minimum_price {
              final_price {
                value
                currency
              }
            }
          }
          media_gallery {
            url(width: 700, height: 700)
            label
          }
          thumbnail {
            url(width: 150, height: 150)
            label
          }
        }
        ... on CustomizableProductInterface {
          options {
            title
            category_options
            option_id
            ... on CustomizableDropDownOption {
              value {
                title
                price
                option_type_id
              }
            }
            ... on CustomizableCheckboxOption {
              value {
                title
                price
                option_type_id
              }
            }
            ... on CustomizableFieldOption {
              field_value: value {
                price
              }
            }
          }
        }

        # Project Specific Attributes
        ffifabrics_vendor_image {
          label
          image
        }
        ffifabrics_vendor
        default_fabric: ffi_dftfabric
        custom_options_finish: fficustopt_finish
        custom_options_type: fficustopt_type
        custom_option_other: fficustopt_add_info
        depth: ffi_frmdimd
        height: ffi_frmdimh
        width: ffi_frmdimw
        senior: ffi_lanesl
        residential: ffi_laners
        contract: ffi_lanect
        designer: ffi_designer
        cube: ffi_cubes
        finish: ffi_stdfinish
        additional_info: ffi_add_info
        assem_req: ffi_assem_req
        materials: ffi_woodspec1
        materials_label: ffi_woodspec1_label
        available_in_leather: ffi_leatheravl
        standard_pillows: ffi_stdpllw1
        fabric_base_yardage: ffi_basefab
        leather_base_square_feet: ffi_baselth
        nailhead_options: ffi_nail_opts
        nailhead_application: ffi_bn_appl
        tablet: ffi_tablet
        cupholder: ffi_cupholder
        standard_seat: ffi_stdcushion
        standard_back: ffi_stdback
        leather: ffifabrics_leather
        weight: ffi_wgtwrp
        spring_construction: ffi_sprgconstr
        casters: ffi_casters
        moisture_barrier_standard: ffi_moistbarr
        seat_construction: ffi_seatconstr
        back_conctruction: ffi_backconstr
        country_of_origin: ffi_countryorg
        width: ffi_frmdimw
        height: ffi_frmdimh
        depth: ffi_frmdimd
        seat_height: ffi_seath
        inside_seat_depth: ffi_insidestd
        inside_seat_width: ffi_insidestw
        inside_back_height: ffi_insidebkh
        office_highest_seat_height: ffi_offhighsth
        office_lowest_seat_height: ffi_offlowsth
        office_highest_arm_height: ffi_offhigharh
        office_lowest_arm_height: ffi_offlowarh
        reclined_depth: ffi_reclindpt
        office_highest_overall_height: ffi_offhighovh
        office_lowest_overall_height: ffi_offlowovh
        apron_height: ffi_apronh
        full_lift_wall_clearance: ffi_fulllftwal
        vinyl: ffifabrics_vinyl
        tv_recline: ffi_tvrecline
        frame_highest_depth: ffi_frmdepthgh
        frame_lowest_depth: ffi_frmdeptlow
        arm_height: ffi_armh
        finish_req: ffi_finish_req
        allowed_finishes: ffi_finishopt
        physicial_distressing: ffifinish_phy_distr
        chemical_distressing: ffifinish_chm_distr
        fairshield_plus: ffifinish_fairshield
        add_info: ffifinish_add_info
        sheen: ffifinish_sheen
        finishes_cleaning: ffifinish_cleaning
        about_fairshield: ffifinish_about
        collection: ffi_collection
        custom_options_finish: fficustopt_finish
        custom_options_type: fficustopt_type
        custom_option_other: fficustopt_add_info
        stockQty: ffifabrics_avail
        wall_clearance: ffi_reclwallcl
        ffi_casegd
        ffi_fullyuph
        ffi_uph_opts
        ffi_lanect
        ffi_laners
        ffi_lanesl
        in_stock_filter
        ffi_avails
        ffi_duedate
        ffi_madeto
        ffi_onords
        ffi_status
        collection_overview_info
        revit_file
        assembly_instructions
        spec_sheet
        # /Project Specific Attributes
      }
    }
  }
`;

export const PRODUCT_ADD_INFO = gql`
  query products($search: String!) {
    products(
      search: $search
      filter: { sku: { eq: $search } }
      pageSize: 1
      currentPage: 1
    ) {
      items {
        uid
        sku
        # Project Specific Attributes
        depth: ffi_frmdimd
        height: ffi_frmdimh
        width: ffi_frmdimw
        senior: ffi_lanesl
        residential: ffi_laners
        contract: ffi_lanect
        designer: ffi_designer
        cube: ffi_cubes
        finish: ffi_stdfinish
        additional_info: ffi_add_info
        materials: ffi_woodspec1
        materials_label: ffi_woodspec1_label
        available_in_leather: ffi_leatheravl
        standard_pillows: ffi_stdpllw1
        fabric_base_yardage: ffi_basefab
        leather_base_square_feet: ffi_baselth
        nailhead_options: ffi_nail_opts
        nailhead_application: ffi_bn_appl
        tablet: ffi_tablet
        cupholder: ffi_cupholder
        standard_seat: ffi_stdcushion
        standard_back: ffi_stdback
        weight: ffi_wgtwrp
        spring_construction: ffi_sprgconstr
        casters: ffi_casters
        moisture_barrier_standard: ffi_moistbarr
        seat_construction: ffi_seatconstr
        back_conctruction: ffi_backconstr
        country_of_origin: ffi_countryorg
        width: ffi_frmdimw
        height: ffi_frmdimh
        depth: ffi_frmdimd
        seat_height: ffi_seath
        inside_seat_depth: ffi_insidestd
        inside_seat_width: ffi_insidestw
        vinyl: ffifabrics_vinyl
        inside_back_height: ffi_insidebkh
        office_highest_seat_height: ffi_offhighsth
        office_lowest_seat_height: ffi_offlowsth
        office_highest_arm_height: ffi_offhigharh
        office_lowest_arm_height: ffi_offlowarh
        reclined_depth: ffi_reclindpt
        office_highest_overall_height: ffi_offhighovh
        office_lowest_overall_height: ffi_offlowovh
        apron_height: ffi_apronh
        leather: ffifabrics_leather
        full_lift_wall_clearance: ffi_fulllftwal
        tv_recline: ffi_tvrecline
        frame_highest_depth: ffi_frmdepthgh
        frame_lowest_depth: ffi_frmdeptlow
        arm_height: ffi_armh
        finish_req: ffi_finish_req
        allowed_finishes: ffi_finishopt
        physicial_distressing: ffifinish_phy_distr
        chemical_distressing: ffifinish_chm_distr
        fairshield_plus: ffifinish_fairshield
        add_info: ffifinish_add_info
        sheen: ffifinish_sheen
        finishes_cleaning: ffifinish_cleaning
        about_fairshield: ffifinish_about
        collection: ffi_collection
        custom_options_finish: fficustopt_finish
        custom_options_type: fficustopt_type
        custom_option_other: fficustopt_add_info
        # /Project Specific Attributes
      }
    }
  }
`;
export const CUSTOMER_PRODUCT_ADD_INFO = gql`
  query products($search: String!) {
    customer {
      email
    }
    products(
      search: $search
      filter: { sku: { eq: $search } }
      pageSize: 1
      currentPage: 1
    ) {
      items {
        uid
        sku
        # Project Specific Attributes
        depth: ffi_frmdimd
        height: ffi_frmdimh
        width: ffi_frmdimw
        senior: ffi_lanesl
        residential: ffi_laners
        contract: ffi_lanect
        designer: ffi_designer
        cube: ffi_cubes
        finish: ffi_stdfinish
        additional_info: ffi_add_info
        materials: ffi_woodspec1
        materials_label: ffi_woodspec1_label
        available_in_leather: ffi_leatheravl
        standard_pillows: ffi_stdpllw1
        fabric_base_yardage: ffi_basefab
        leather_base_square_feet: ffi_baselth
        nailhead_options: ffi_nail_opts
        nailhead_application: ffi_bn_appl
        tablet: ffi_tablet
        cupholder: ffi_cupholder
        standard_seat: ffi_stdcushion
        standard_back: ffi_stdback
        weight: ffi_wgtwrp
        spring_construction: ffi_sprgconstr
        casters: ffi_casters
        moisture_barrier_standard: ffi_moistbarr
        leather: ffifabrics_leather
        seat_construction: ffi_seatconstr
        back_conctruction: ffi_backconstr
        country_of_origin: ffi_countryorg
        width: ffi_frmdimw
        height: ffi_frmdimh
        depth: ffi_frmdimd
        seat_height: ffi_seath
        inside_seat_depth: ffi_insidestd
        inside_seat_width: ffi_insidestw
        inside_back_height: ffi_insidebkh
        office_highest_seat_height: ffi_offhighsth
        office_lowest_seat_height: ffi_offlowsth
        office_highest_arm_height: ffi_offhigharh
        office_lowest_arm_height: ffi_offlowarh
        reclined_depth: ffi_reclindpt
        office_highest_overall_height: ffi_offhighovh
        office_lowest_overall_height: ffi_offlowovh
        apron_height: ffi_apronh
        full_lift_wall_clearance: ffi_fulllftwal
        vinyl: ffifabrics_vinyl
        tv_recline: ffi_tvrecline
        frame_highest_depth: ffi_frmdepthgh
        frame_lowest_depth: ffi_frmdeptlow
        arm_height: ffi_armh
        finish_req: ffi_finish_req
        allowed_finishes: ffi_finishopt
        physicial_distressing: ffifinish_phy_distr
        chemical_distressing: ffifinish_chm_distr
        fairshield_plus: ffifinish_fairshield
        add_info: ffifinish_add_info
        sheen: ffifinish_sheen
        finishes_cleaning: ffifinish_cleaning
        about_fairshield: ffifinish_about
        collection: ffi_collection
        custom_options_finish: fficustopt_finish
        custom_options_type: fficustopt_type
        custom_option_other: fficustopt_add_info
        stockQty: ffifabrics_avail
        # /Project Specific Attributes
      }
    }
  }
`;

export const CUSTOMER_PRODUCT = gql`
  query products($filter: ProductAttributeFilterInput!, $search: String) {
    customer {
      email
    }
    products(filter: $filter, search: $search) {
      aggregations {
        attribute_code
        options {
          count
          label
          value
        }
        label
        count
      }
      items {
        ffi_extdesc2
        ffi_name
        id
        uid
        categories {
          id
          name
        }
        name
        sku
        url_key
        ffi_riser
        ffi_finishopt
        ffifabrics_std_width
        ffifabrics_vrepeat
        ffifabrics_hrepeat
        ffifabrics_fullcont
        ffifabrics_fabric
        description {
          html
        }
        ffifabrics_vendor_image {
          label
          image
        }
        ffifabrics_vendor
        meta_description
        meta_title
        image {
          url(width: 700, height: 700)
          label
          disabled
        }
        media_gallery {
          url(width: 700, height: 700)
          label
          types
        }
        small_image {
          url(width: 265, height: 265)
          label
          disabled
        }
        price_range {
          minimum_price {
            final_price {
              value
              currency
            }
          }
        }
        attribute_set_id
        attribute_set_name
        related_products {
          id
          sku
          name
          url_key
          ffi_lanect
          ffi_laners
          ffi_lanesl
          ffi_isvisible
          is_new
          description {
            html
          }
          image {
            url(width: 700, height: 700)
            label
          }
          small_image {
            url(width: 265, height: 265)
            label
          }
          ffi_frmdimw
          ffi_frmdimh
          price_range {
            minimum_price {
              final_price {
                value
                currency
              }
            }
          }
          thumbnail {
            url(width: 150, height: 150)
            label
          }
        }
        ... on CustomizableProductInterface {
          options {
            title
            category_options
            option_id
            ... on CustomizableDropDownOption {
              value {
                title
                price
                option_type_id
              }
            }
            ... on CustomizableCheckboxOption {
              value {
                title
                price
                option_type_id
              }
            }
            ... on CustomizableFieldOption {
              field_value: value {
                price
              }
            }
          }
        }

        # Project Specific Attributes
        depth: ffi_frmdimd
        height: ffi_frmdimh
        width: ffi_frmdimw
        senior: ffi_lanesl
        residential: ffi_laners
        contract: ffi_lanect
        designer: ffi_designer
        finish: ffi_stdfinish
        additional_info: ffi_add_info
        materials: ffi_woodspec1
        materials_label: ffi_woodspec1_label
        assem_req: ffi_assem_req
        cube: ffi_cubes
        available_in_leather: ffi_leatheravl
        standard_pillows: ffi_stdpllw1
        fabric_base_yardage: ffi_basefab
        leather_base_square_feet: ffi_baselth
        nailhead_options: ffi_nail_opts
        nailhead_application: ffi_bn_appl
        tablet: ffi_tablet
        cupholder: ffi_cupholder
        standard_seat: ffi_stdcushion
        standard_back: ffi_stdback
        weight: ffi_wgtwrp
        spring_construction: ffi_sprgconstr
        casters: ffi_casters
        moisture_barrier_standard: ffi_moistbarr
        ffi_casegd
        ffi_fullyuph
        ffi_uph_opts
        seat_construction: ffi_seatconstr
        back_conctruction: ffi_backconstr
        country_of_origin: ffi_countryorg
        width: ffi_frmdimw
        height: ffi_frmdimh
        depth: ffi_frmdimd
        seat_height: ffi_seath
        inside_seat_depth: ffi_insidestd
        inside_seat_width: ffi_insidestw
        inside_back_height: ffi_insidebkh
        leather: ffifabrics_leather
        office_highest_seat_height: ffi_offhighsth
        office_lowest_seat_height: ffi_offlowsth
        office_highest_arm_height: ffi_offhigharh
        office_lowest_arm_height: ffi_offlowarh
        reclined_depth: ffi_reclindpt
        office_highest_overall_height: ffi_offhighovh
        office_lowest_overall_height: ffi_offlowovh
        apron_height: ffi_apronh
        full_lift_wall_clearance: ffi_fulllftwal
        vinyl: ffifabrics_vinyl
        tv_recline: ffi_tvrecline
        frame_highest_depth: ffi_frmdepthgh
        frame_lowest_depth: ffi_frmdeptlow
        arm_height: ffi_armh
        default_fabric: ffi_dftfabric
        collection: ffi_collection
        custom_options_finish: fficustopt_finish
        custom_options_type: fficustopt_type
        custom_option_other: fficustopt_add_info
        stockQty: ffifabrics_avail
        colorways: ffifabrics_colorways
        abrasion: ffifabrics_abrasion
        country: ffifabrics_country
        category1: ffifabrics_category1
        collection_fabric: ffifabrics_collection
        pattern: ffifabrics_pattern
        grade: ffifabrics_grade
        color: ffifabrics_descr
        standard_description: ffifabrics_descript
        wall_clearance: ffi_reclwallcl
        add_info: ffifinish_add_info
        ffi_lanect
        ffi_laners
        ffi_lanesl
        in_stock_filter
        finish_req: ffi_finish_req
        allowed_finishes: ffi_finishopt
        ffi_avails
        ffi_duedate
        ffi_madeto
        ffi_onords
        ffi_status
        about_fairshield: ffifinish_about
        finishes_cleaning: ffifinish_cleaning
        physicial_distressing: ffifinish_phy_distr
        chemical_distressing: ffifinish_chm_distr
        sheen: ffifinish_sheen
        collection_overview_info
        revit_file
        assembly_instructions
        spec_sheet
      }
    }
  }
`;
