import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";

const SkeletonCategory = ({ loadingPage = false }) => {
  let isMobile = useMediaQuery({ maxWidth: 992 });
  return !isMobile ? (
    <>
      {/* {loadingPage && (
        <MDBContainer fluid className={"p-0 overflow-hidden"}>
          <MDBRow>
            <MDBCol>
              <Skeleton height="69.7rem" width="100%" />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      )} */}
      <MDBContainer>
        {loadingPage && (
          <MDBRow className="my-5 py-md-5">
            <MDBCol>
              <Skeleton height="4rem" />
            </MDBCol>
          </MDBRow>
        )}
        <MDBRow>
          <MDBCol size="12" className="text-center">
            <Skeleton
              duration={2}
              className={`m-3`}
              width={`22.8%`}
              count={`12`}
              height={`33.2rem`}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className="my-5 py-md-5">
          <MDBCol>
            <Skeleton height="4rem" />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  ) : (
    <>
      <MDBContainer fluid className={"p-0 overflow-hidden"}>
        <MDBRow>
          <MDBCol>
            <Skeleton height="58.6rem" width="100%" />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBContainer>
        <MDBRow className="my-5 py-md-5">
          <MDBCol>
            <Skeleton height="4rem" />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="6">
            <Skeleton
              duration={2}
              className={`my-3`}
              width={`50%`}
              count={`8`}
              height={`23.5rem`}
            />
          </MDBCol>
          <MDBCol size="6">
            <Skeleton
              duration={2}
              className={`my-3`}
              width={`50%`}
              count={`8`}
              height={`23.5rem`}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className="my-5 py-md-5">
          <MDBCol>
            <Skeleton height="4rem" />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default SkeletonCategory;
