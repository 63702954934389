import React, { memo, useEffect } from "react";
import { MDBCol, MDBRow, MDBBox, MDBTypography, MDBIcon } from "mdbreact";
import Button from "core/components/button";
import themeConfiguration from "config/themeConfiguration";
import Breadcrumbs from "app/layout/breadcrumbs";

const HERO_BANNER_COL = "hero-banner-col";

const HeroBanner = memo(
  ({
    heroImage,
    description,
    name,
    className,
    showPDFNotCustom,
    categoryInfo,
    category,
  }) => {
    let heroImageParts = heroImage.split("/");
    let imgName = heroImageParts.pop();
    let showHeroImage = true;

    let downloadBrochure = categoryInfo?.catalog_pdf;
    let downloadBrochure2 = categoryInfo?.catalog_pdf_2;
    let downloadBrochureTitle = categoryInfo?.catalog_pdf_title;
    let downloadBrochureTitle2 = categoryInfo?.catalog_pdf_title2;
    if (!heroImage.includes("media") && !heroImage.includes("placeholder")) {
      heroImage =
        heroImageParts.join("/") + "/pub/media/catalog/category/" + imgName;
      if (!heroImage.includes(".jpg")) {
        heroImage = heroImage + ".jpg";
      }
    }
    if (
      heroImage?.toLowerCase()?.includes("placeholder") ||
      heroImage?.toLowerCase()?.includes("undefined")
    )
      showHeroImage = false;

    useEffect(() => {
      const heroBannerCol = document.getElementById(HERO_BANNER_COL);
      if (!heroBannerCol) return;
      if (!showHeroImage)
        heroBannerCol.parentElement.style.backgroundColor = "#a8aeb5";
      else heroBannerCol.parentElement.style.backgroundColor = "";
    }, [showHeroImage]);

    const specialCharacterName = name.replace(/&ntilde;/g, "ñ");
    return (
      <>
        {showHeroImage && (
          <MDBCol size="12" lg="6" className="px-0">
            <img
              className="img-fluid w-100 h-100"
              src={heroImage}
              alt={"category hero"}
            />
          </MDBCol>
        )}
        <MDBCol
          size="12"
          lg={6}
          id={HERO_BANNER_COL}
          className={`${className} ${
            showHeroImage ? "bg-secondary" : "mt-5"
          } d-flex justify-content-center align-items-center my-0`.trim()}
        >
          <MDBRow className={`w-responsive py-5 px-0 px-lg-100 py-lg-6`}>
            <MDBCol>
              <MDBRow className="mb-4">
                <MDBCol>
                  <MDBTypography
                    tag="h2"
                    variant="display-1"
                    className={`text-center ${"text-white"}`.trim()}
                  >
                    {specialCharacterName ? specialCharacterName : name}
                  </MDBTypography>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <Breadcrumbs
                    className="justify-content-center"
                    itemClass={`text-white`}
                    linkClass={"text-white"}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="my-3 py-4">
                <MDBBox className={`separator`} />
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBTypography
                    tag="p"
                    className={`body-3 text-center fwregular ${
                      showHeroImage ? "description-hero" : "text-default"
                    }`.trim()}
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </MDBCol>
              </MDBRow>
              {showPDFNotCustom && typeof showPDFNotCustom !== "undefined" && (
                <MDBRow>
                  <MDBCol>
                    {downloadBrochure2 || downloadBrochure ? (
                      <MDBRow
                        className={`mt-4 ${
                          downloadBrochure2 && downloadBrochure
                            ? "collection-notcustom-buttons-pdf"
                            : "collection-notcustom-buttons-pdf-single justify-content-center"
                        }`}
                      >
                        {(downloadBrochure && (
                          <MDBCol size="6">
                            <Button
                              className="btn-default btn w-100"
                              onClick={() => {
                                window.open(
                                  themeConfiguration.magento_url +
                                    themeConfiguration.media_url +
                                    themeConfiguration.category_url +
                                    "/" +
                                    downloadBrochure,
                                  "_blank"
                                );
                              }}
                            >
                              <MDBIcon far icon="file-pdf" className="mr-3" />
                              <span
                                style={{
                                  whiteSpace: "nowrap",
                                  fontSize: "1.4rem",
                                  color: "#FFFFFF",
                                  fontWeight: "400",
                                }}
                              >
                                {downloadBrochureTitle}
                              </span>
                            </Button>
                          </MDBCol>
                        )) || <></>}
                        {(downloadBrochure2 && (
                          <MDBCol size="6">
                            <Button
                              className="btn-default btn w-100"
                              onClick={() => {
                                window.open(
                                  themeConfiguration.magento_url +
                                    themeConfiguration.media_url +
                                    themeConfiguration.category_url +
                                    "/" +
                                    downloadBrochure2,
                                  "_blank"
                                );
                              }}
                            >
                              <MDBIcon far icon="file-pdf" className="mr-3" />
                              <span
                                style={{
                                  whiteSpace: "nowrap",
                                  fontSize: "1.4rem",
                                  color: "#FFFFFF",
                                  fontWeight: "400",
                                }}
                              >
                                {downloadBrochureTitle2}
                              </span>
                            </Button>
                          </MDBCol>
                        )) || <></>}
                      </MDBRow>
                    ) : (
                      <></>
                    )}
                  </MDBCol>
                </MDBRow>
              )}
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </>
    );
  }
);

export default HeroBanner;
