import apolloClient from "core/graphql/apolloClient";
export const formatFileName = (name: string) =>
  name.replace(/[&\/\\#,+()$~%!'": \s*?<>{}"\b \b"\u2013|\u2014\U+2014]/g, "_");

export type PathsConfig = {
  label: string;
  path: string;
  children: Record<string, PathsConfig>;
};

export const clearSiteData = (): void => {
  apolloClient.clearStore();
  window.localStorage.clear();
  window.location.reload(true);
};

export const getPaths = (path: string) => <Config extends PathsConfig>(
  config: Config
): Config => {
  const fullPath = `${path}/${config.path}`;

  return {
    ...config,
    path: fullPath,
    children: Object.entries(config.children).reduce((paths, [key, value]) => {
      return {
        ...paths,
        [key]: getPaths(fullPath)(value),
      };
    }, {} as PathsConfig),
  };
};

export const createKeyHandler = (key: string) => <
  Handler extends React.EventHandler<React.SyntheticEvent>
>(
  handler: Handler
): React.KeyboardEventHandler => (e) => {
  if (e.key === key) {
    handler(e);
  }
};

export const pathnameIncludes = (path: string) =>
  window.location.pathname.includes(path);

/**
 * Returns value from url search param, if it exists. Otherwise returns default value.
 * @param {string} param
 * @param {number} defaultValue
 * @returns
 */
export function getParamValue(
  param: string,
  defaultValue: number | string | null
): number | string | null {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.get(param);
  return urlParams.get(param) ? urlParams.get(param) : defaultValue;
}

export const createEnterHandler = createKeyHandler("Enter");
